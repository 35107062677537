import { 
  Box, 
  Flex, 
  IconButton, 
  Input,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  Modal,
  Button,
  Text } from '@chakra-ui/react';
import EmojiData from '@emoji-mart/data';
import EmojiPicker from '@emoji-mart/react';
import React, { useEffect, useRef, useState } from 'react';
import { FaGrinWink, FaPaperclip } from 'react-icons/fa';
import { IoPaperPlaneOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector, updateTypeConversation } from '../../../app/Auth/AuthSlice.js';
import {
  layoutSetupSelector,
  updateHeightBody,
} from '../../../app/Layouts/LayoutSlice';
import { uploadFile } from '../../../app/Message/MessageApi';
import {
  clearFileData,
  messageSelector,
  resetMessage,
  updateEmojiStatus,
  updateFileData,
  updateIsModalPrev,
  updateIsResetMessage,
  updateMessage,
} from '../../../app/Message/MessageSlice';
import {
  getCategoryFile,
  getExtensionFile,
  notify,
  validationSizeFile,
} from '../../Utils/helpers';
import { sendMessage, typingMessage, resolveChat } from '../../WebSocket/Clients/ClientActions';
import DivContenteditable from '../DivContenteditable/DivContenteditable';
import './Emoji.css';
import styles from './InputMessage.module.css';
import {clearFaq} from '../../../app/FAQ/FaqSlice.js'


function InputMessage() {
  /* general config */
  const dispatch = useDispatch();
  const { deviceVersion } = useSelector(layoutSetupSelector);
  const {
    message,
    chatId,
    isModalPreview,
    isResetMessage,
    isOpenEmoji,
    emojiProps,
    fileType,
    fileName,
    fileUrl,
    filePath,
    fileId,
  } = useSelector(messageSelector);
  const { apiKey, typeConversation, clientSessions } = useSelector(authSelector);
  const [objContainerFooter, setObjContainerFooter] = useState({});
  const [file, setFile] = useState();
  const setRefMsg = useRef(message);
  const triggerFileInput = useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();

  /* handler */
  const handlerSendMessage = () => {
    const elMsg = document.getElementById('input-message');

    const customizeMsg = elMsg.innerText
      .replace(/&nbsp;/g, '')
      .replace(/<br\s*\/?>/gi, '\n')
      .trim();
    dispatch(resetMessage());
    sendMessage(chatId, customizeMsg);
    handlerUpdateStatusEmoji(false);
    return dispatch(updateIsResetMessage(true));
  };

  const handlerSetupCardBody = (val) => {
    const dataHeight = {
      minHeight: '0px',
      maxHeight: '0px',
    };
    if (Boolean(isOpenEmoji)) {
      if (deviceVersion === 'small_desktop') {
        switch (true) {
          case val >= 150:
            dataHeight.maxHeight = '50px';
            dataHeight.minHeight = '50px';
            break;
          case val >= 131:
            dataHeight.maxHeight = '60px';
            dataHeight.minHeight = '60px';
            break;
          case val >= 110:
            dataHeight.maxHeight = '70px';
            dataHeight.minHeight = '70px';
            break;
          case val >= 89:
            dataHeight.maxHeight = '80px';
            dataHeight.minHeight = '80px';
            break;
          case val >= 68:
            dataHeight.maxHeight = '100px';
            dataHeight.minHeight = '100px';
            break;
          default:
            dataHeight.maxHeight = '120px';
            dataHeight.minHeight = '120px';
            break;
        }
      } else {
        switch (true) {
          case val >= 150:
            dataHeight.maxHeight = '76px';
            dataHeight.minHeight = '76px';
            break;
          case val >= 131:
            dataHeight.maxHeight = '95px';
            dataHeight.minHeight = '95px';
            break;
          case val >= 110:
            dataHeight.maxHeight = '116px';
            dataHeight.minHeight = '116px';
            break;
          case val >= 89:
            dataHeight.maxHeight = '137px';
            dataHeight.minHeight = '137px';
            break;
          case val >= 68:
            dataHeight.maxHeight = '158px';
            dataHeight.minHeight = '158px';
            break;
          default:
            dataHeight.maxHeight = '170px';
            dataHeight.minHeight = '170px';
            break;
        }
      }
    } else {
      if (deviceVersion === 'small_desktop') {
        switch (true) {
          case val >= 150:
            dataHeight.maxHeight = '280px';
            dataHeight.minHeight = '280px';
            break;
          case val >= 131:
            dataHeight.maxHeight = '290px';
            dataHeight.minHeight = '290px';
            break;
          case val >= 110:
            dataHeight.maxHeight = '300px';
            dataHeight.minHeight = '300px';
            break;
          case val >= 89:
            dataHeight.maxHeight = '310px';
            dataHeight.minHeight = '310px';
            break;
          case val >= 68:
            dataHeight.maxHeight = '325px';
            dataHeight.minHeight = '325px';
            break;
          default:
            dataHeight.maxHeight = '350px';
            dataHeight.minHeight = '350px';
            break;
        }
      } else {
        switch (true) {
          case val >= 150:
            dataHeight.maxHeight = '306px';
            dataHeight.minHeight = '306px';
            break;
          case val >= 131:
            dataHeight.maxHeight = '325px';
            dataHeight.minHeight = '325px';
            break;
          case val >= 110:
            dataHeight.maxHeight = '346px';
            dataHeight.minHeight = '346px';
            break;
          case val >= 89:
            dataHeight.maxHeight = '367px';
            dataHeight.minHeight = '367px';
            break;
          case val >= 68:
            dataHeight.maxHeight = '388px';
            dataHeight.minHeight = '388px';
            break;
          default:
            dataHeight.maxHeight = '400px';
            dataHeight.minHeight = '400px';
            break;
        }
      }
    }
    dispatch(updateHeightBody(dataHeight));
  };

  const handlerPasteValue = (value) => {
    let sel, range;

    /* call auto focus on input message */
    if (window.getSelection) {
      sel = window.getSelection();
      handlerFocusInputChat();

      if (sel.getRangeAt && sel.rangeCount) {
        range = sel.getRangeAt(0);
        range.deleteContents();

        /* create element use to node counting */
        const createEl = document.createElement('div');
        createEl.innerHTML = value;
        let frag = document.createDocumentFragment(),
          node,
          lastNode;

        while ((node = createEl.firstChild)) {
          lastNode = frag.appendChild(node);
        }

        range.insertNode(frag);
        // Preserve the selection
        if (lastNode) {
          range = range.cloneRange();
          range.setStartAfter(lastNode);
          range.collapse(true);
          sel.removeAllRanges();
          sel.addRange(range);

          const elInputMessage = document.getElementById('input-message');
          dispatch(updateMessage(elInputMessage.innerHTML));
        }
      }
    } else if (document.selection && document.selection.type !== 'Control') {
      document.selection.createRange().pasteHTML(value);
      const elInputMessage = document.getElementById('input-message');
      dispatch(updateMessage(elInputMessage.innerHTML));
    }
  };

  const handlerOnInputMessage = (event) => {
    const element = event.target;
    const elClientHeight = element.clientHeight;
    
    typingMessage(chatId,true);

    dispatch(updateMessage(element.innerHTML));
    handlerSetupCardBody(elClientHeight);
  };

  const handlerOnPasteMessage = (event) => {
    event.stopPropagation();
    event.preventDefault();

    let cbPayload = [
      ...(event.clipboardData || event.originalEvent.clipboardData).items,
    ];

    const dataFile = cbPayload[0].getAsFile();
    if (dataFile) {
      handlerUpdateStatusEmoji(false);
      setFile(dataFile);
    } else {
      let txtPaste = event.clipboardData.getData('text/plain');
      document.execCommand('insertText', false, txtPaste);
      const elMsg = document.getElementById('input-message');
      handlerSetupCardBody(elMsg.clientHeight);
    }
  };

  const handlerKey = (event) => {
    if (event.ctrlKey && [66, 73, 85].includes(event.keyCode)) {
      event.preventDefault();
      return false;
    }

    let customizeMessage = message
      .replace(/<\/div>/gi, '')
      .replace(/<br\s*\/?>/gi, '')
      .replace(/\s\s+/g, '')
      .replace(/&nbsp;/g, '')
      .trim();
    if (event.which === 13 && !event.shiftKey) {
      if (customizeMessage.length < 1) {
        event.preventDefault();
        return notify('error', 4000, 'Masukan pesan text atau file!');
      } else if (customizeMessage.length > 5000) {
        console.log(customizeMessage.length);
        event.preventDefault();
        return notify('warn', 4000, 'Maksimal panjang pesan 5000 karakter!');
      } else {
        event.preventDefault();
        return handlerSendMessage();
      }
    }

    if ([46, 8].includes(event.which)) {
      if (customizeMessage.length < 1) {
        dispatch(updateIsResetMessage(true));
        return dispatch(resetMessage(''));
      }
    }
  };

  const handlerClickSend = () => {
    let customizeMessage = message
      .replace(/<\/div>/gi, '')
      .replace(/^(<br>)+|(<br>)+$/g, '')
      .replace(/\s\s+/g, '')
      .replace(/&nbsp;/g, '')
      .trim();

    if (customizeMessage.length < 1) {
      notify('error', 4000, 'Masukan pesan text atau file!');
    } else if (customizeMessage.length > 5000) {
      console.log(customizeMessage.length);
      return notify('warn', 4000, 'Maksimal panjang pesan 5000 karakter!');
    } else {
      handlerSendMessage();
    }
    console.timeEnd('handlerClickSend');
  };

  const handlerChooseFile = (event) => {
    const fileChoosed = !Boolean(event.target.files[0])
      ? null
      : event.target.files[0];
    setFile(fileChoosed);
  };

  const handlerClearChooseFile = () => {
    document.getElementById('idInputFile').value = null;
  };

  const handlerUpdateStatusEmoji = (value) => {
    dispatch(updateEmojiStatus(value));
  };

  const handlerButtonEmoji = () => {
    const isStatusUpdate = !Boolean(isOpenEmoji) ? true : false;
    handlerUpdateStatusEmoji(isStatusUpdate);
  };

  const handlerChooseEmoji = (value) => {
    handlerPasteValue(value.native);
  };

  const handlerFocusInputChat = () => {
    document.getElementById('input-message').focus();
  };

  const handlerChatAgent = () => {
    sendMessage(clientSessions.chatId, 'Chat dengan agent Qwords')
    dispatch(updateTypeConversation({type: 'general', question_id:null}))
  }

  const handlerResolveChat = () => {
    dispatch(clearFaq());
    resolveChat(chatId);
    onClose(true);
  };

  useEffect(() => {
    if (
      ['tablet', 'large_mobile', 'medium_mobile', 'small_mobile'].includes(
        deviceVersion
      )
    ) {
      setObjContainerFooter({
        borderRadius: '0',
      });
    } else {
      setObjContainerFooter({
        boxSizing: 'border-box',
        borderRadius: '0 0 15px 15px',
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        boxShadow: 'lg',
        borderTopColor: 'lightGray',
      });
    }
  }, [deviceVersion]);

  useEffect(() => {
    if (isResetMessage) {
      const elMsg = document.getElementById('input-message');
      elMsg.innerText = message;
      dispatch(updateIsResetMessage(false));
      const dataHeight = {
        minHeight: '0',
        maxHeight: '0',
      };
      if (isOpenEmoji) {
        if (deviceVersion === 'small_desktop') {
          dataHeight.minHeight = '120px';
          dataHeight.maxHeight = '120px';
        } else {
          dataHeight.minHeight = '170px';
          dataHeight.maxHeight = '170px';
        }
      } else {
        if (deviceVersion === 'small_desktop') {
          dataHeight.minHeight = '350px';
          dataHeight.maxHeight = '350px';
        } else {
          dataHeight.minHeight = '400px';
          dataHeight.maxHeight = '400px';
        }
      }
      dispatch(updateHeightBody(dataHeight));
    }
  }, [isResetMessage]);

  useEffect(() => {
    if (Boolean(file)) {
      const getExtFile = getExtensionFile(file.name);
      const categoryFile = getCategoryFile(getExtFile);
      dispatch(clearFileData());
      dispatch(updateIsModalPrev(false));

      if (!Boolean(categoryFile)) {
        handlerClearChooseFile();
        setFile(null);
        notify('warn', 3000, `Ekstensi ${getExtFile} tidak tersedia!`);
      } else if (!Boolean(validationSizeFile(categoryFile, file.size))) {
        handlerClearChooseFile();
        setFile(null);

        notify(
          'warn',
          3000,
          `File ${getExtFile} maks ukuran: ${categoryFile.unit}!`
        );
      } else {
        uploadFile(apiKey, file)
          .then((response) => {
            const uploadFile = response.data.data;
            const data = {
              fileName: uploadFile.name,
              filePath: uploadFile.path,
              fileType: uploadFile.type,
              fileUrl: uploadFile.url,
              fileId: uploadFile.id,
            };
            dispatch(updateFileData(data));
            dispatch(updateIsModalPrev(true));
          })
          .catch((err) => {
            handlerClearChooseFile();
            notify('error', 5000, err.response.data.message);
          });
      }
    } else {
      dispatch(clearFileData());
      handlerClearChooseFile(apiKey, file);
      setFile(null);
    }
  }, [file]);

  useEffect(() => {
    if (!Boolean(isModalPreview)) {
      dispatch(clearFileData());
      handlerClearChooseFile(apiKey, file);
    }
  }, [isModalPreview]);

  useEffect(() => {
    handlerFocusInputChat();

    document
      .getElementById('input-message')
      .addEventListener('paste', handlerOnPasteMessage);
  }, []);

  if(Boolean(fileType)){
    const dataFile = {
      file_type: fileType,
      file_name: fileName,
      file_url: fileUrl,
      file_path: filePath,
      file_id: fileId,
    };
    sendMessage(chatId, '', dataFile);
    dispatch(updateIsResetMessage(true));
    dispatch(updateIsModalPrev(false));
    dispatch(clearFileData());
    dispatch(resetMessage());
  }

  return (
    <>
      <Box w="100%" bg="whitePrimary" {...objContainerFooter}>
        {Boolean(isOpenEmoji) &&
          !['tablet', 'large_mobile', 'medium_mobile', 'small_mobile'].includes(
            deviceVersion
          ) && (
            <EmojiPicker
              data={EmojiData}
              onEmojiSelect={handlerChooseEmoji}
              autoFocus={true}
              skin="1"
              emojiVersion={14}
              previewPosition="none"
              theme="light"
              skinTonePosition="none"
              searchPosition="none"
              noCountryFlags={false}
              {...emojiProps}
            />
          )}
        <Flex>
          {typeConversation.type === 'faq' ? 
          <>
            <Box
              display='flex'
              w='100%'
              p='10px'
            >
            <Box
                  as='button'
                  py='10px'
                  textAlign='center'
                  width='100%'
                  onClick={onOpen}
                  borderRadius='8px'
                  border='1px solid #FF6666'
                  color='#FF6666'
                  mr='5px'
                  _hover={{ bg: '#FF6666', color: '#fff', fontWeight: 'bold' }}
                  _active={{
                    bg: '#dddfe2',
                    transform: 'scale(0.98)',
                    borderColor: '#bec3c9',
                  }}
                  _focus={{
                    boxShadow:
                      '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
                    borderColor: '#ff8d0b'
                  }}
                >
                Selesaikan Chat
              </Box>
              <Box
                as='button'
                py='10px'
                textAlign='center'
                width='100%'
                onClick={handlerChatAgent}
                borderRadius='8px'
                border='1px solid #F3AA60'
                color='#F3AA60'
                ml='5px'
                _hover={{ bg: '#F3AA60', color: '#fff', fontWeight: 'bold' }}
                _active={{
                  bg: '#dddfe2',
                  transform: 'scale(0.98)',
                  borderColor: '#bec3c9',
                }}
                _focus={{
                  boxShadow:
                    '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
                  borderColor: '#ff8d0b'
                }}
              >
                Chat dengan CS
              </Box>
            </Box>
            <Box w="100%" display="none">
                <DivContenteditable
                  attrProps={{
                    id: 'input-message',
                    className: styles['input-message'],
                    placeholder: 'Masukan pesan...',
                    contentEditable: true,
                  }}
                  handlerInput={handlerOnInputMessage}
                  handlerKey={handlerKey}
                  setupRef={setRefMsg}
                />
            </Box>
            <Box py="3" px="5" display="none">
              <Flex justify="center">
                {![
                  'tablet',
                  'large_mobile',
                  'medium_mobile',
                  'small_mobile',
                ].includes(deviceVersion) && (
                  <IconButton
                    bg="transparent"
                    color="normalGray"
                    aria-label="icon-attachment"
                    mr="0.5"
                    size="sm"
                    fontSize="lg"
                    border="none"
                    shadow="none"
                    cursor="pointer"
                    isActive={isOpenEmoji}
                    onClick={() => handlerButtonEmoji()}
                    _hover={{
                      bg: 'transparent',
                      color: 'darkGray',
                    }}
                    _focus={{
                      bg: 'transparent',
                      color: 'darkGray',
                    }}
                    _focusVisible={{
                      bg: 'transparent',
                      color: 'darkGray',
                    }}
                    _active={{
                      bg: 'whitePrimaryHover',
                      color: 'darkGray',
                      borderRadius: '50%',
                    }}
                    icon={<FaGrinWink />}
                  />
                )}
                <IconButton
                  bg="transparent"
                  color="normalGray"
                  aria-label="icon-attachment"
                  mr="0.5"
                  border="none"
                  shadow="none"
                  cursor="pointer"
                  size="sm"
                  fontSize="lg"
                  onClick={() => triggerFileInput.current.click()}
                  _hover={{
                    bg: 'transparent',
                    color: 'darkGray',
                  }}
                  _focus={{
                    bg: 'transparent',
                    color: 'darkGray',
                  }}
                  _focusVisible={{
                    bg: 'transparent',
                    color: 'darkGray',
                  }}
                  _active={{
                    bg: 'transparent',
                    color: 'darkGray',
                  }}
                  icon={<FaPaperclip />}
                />
                <IconButton
                  borderRadius="50%"
                  bg="primary"
                  borderColor="transparent"
                  color="whitePrimary"
                  aria-label="send-message-icon"
                  size="sm"
                  fontSize="lg"
                  cursor="pointer"
                  onClick={() => handlerClickSend()}
                  _hover={{
                    bg: 'primaryHover',
                    color: 'whitePrimaryHover',
                  }}
                  _focus={{
                    bg: 'primaryHover',
                    color: 'whitePrimaryHover',
                  }}
                  _focusVisible={{
                    bg: 'primaryHover',
                    color: 'whitePrimaryHover',
                  }}
                  _active={{
                    bg: 'primaryHover',
                    color: 'whitePrimaryHover',
                  }}
                  icon={<IoPaperPlaneOutline />}
                />
              </Flex>
              <Input
                type="file"
                id="idInputFile"
                ref={triggerFileInput}
                display="none"
                onChange={handlerChooseFile}
                accept=".crt, .csr, .doc, .docx, .pdf, .txt, .xls, .xlsx,.jpg,.jpeg,.png,.webp,.zip,.rar"
              />
            </Box>
          </> : 
          <>
            <Box w="100%">
                <DivContenteditable
                  attrProps={{
                    id: 'input-message',
                    className: styles['input-message'],
                    placeholder: 'Masukan pesan...',
                    contentEditable: true,
                  }}
                  handlerInput={handlerOnInputMessage}
                  handlerKey={handlerKey}
                  setupRef={setRefMsg}
                />
            </Box>
            <Box py="3" px="5">
              <Flex justify="center">
                {![
                  'tablet',
                  'large_mobile',
                  'medium_mobile',
                  'small_mobile',
                ].includes(deviceVersion) && (
                  <IconButton
                    bg="transparent"
                    color="normalGray"
                    aria-label="icon-attachment"
                    mr="0.5"
                    size="sm"
                    fontSize="lg"
                    border="none"
                    shadow="none"
                    cursor="pointer"
                    isActive={isOpenEmoji}
                    onClick={() => handlerButtonEmoji()}
                    _hover={{
                      bg: 'transparent',
                      color: 'darkGray',
                    }}
                    _focus={{
                      bg: 'transparent',
                      color: 'darkGray',
                    }}
                    _focusVisible={{
                      bg: 'transparent',
                      color: 'darkGray',
                    }}
                    _active={{
                      bg: 'whitePrimaryHover',
                      color: 'darkGray',
                      borderRadius: '50%',
                    }}
                    icon={<FaGrinWink />}
                  />
                )}
                <IconButton
                  bg="transparent"
                  color="normalGray"
                  aria-label="icon-attachment"
                  mr="0.5"
                  border="none"
                  shadow="none"
                  cursor="pointer"
                  size="sm"
                  fontSize="lg"
                  onClick={() => triggerFileInput.current.click()}
                  _hover={{
                    bg: 'transparent',
                    color: 'darkGray',
                  }}
                  _focus={{
                    bg: 'transparent',
                    color: 'darkGray',
                  }}
                  _focusVisible={{
                    bg: 'transparent',
                    color: 'darkGray',
                  }}
                  _active={{
                    bg: 'transparent',
                    color: 'darkGray',
                  }}
                  icon={<FaPaperclip />}
                />
                <IconButton
                  borderRadius="50%"
                  bg="primary"
                  borderColor="transparent"
                  color="whitePrimary"
                  aria-label="send-message-icon"
                  size="sm"
                  fontSize="lg"
                  cursor="pointer"
                  onClick={() => handlerClickSend()}
                  _hover={{
                    bg: 'primaryHover',
                    color: 'whitePrimaryHover',
                  }}
                  _focus={{
                    bg: 'primaryHover',
                    color: 'whitePrimaryHover',
                  }}
                  _focusVisible={{
                    bg: 'primaryHover',
                    color: 'whitePrimaryHover',
                  }}
                  _active={{
                    bg: 'primaryHover',
                    color: 'whitePrimaryHover',
                  }}
                  icon={<IoPaperPlaneOutline />}
                />
              </Flex>
              <Input
                type="file"
                id="idInputFile"
                ref={triggerFileInput}
                display="none"
                onChange={handlerChooseFile}
                accept=".crt, .csr, .doc, .docx, .pdf, .txt, .xls, .xlsx,.jpg,.jpeg,.png,.webp,.zip,.rar"
              />
            </Box>
          </>
          }
        </Flex>
      </Box>
      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose} size="xs">
        <ModalOverlay />
        <ModalContent  >
          <ModalHeader>Selesaikan Chat?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text  mb='1rem'>
              Apakah anda ingin menyelesaikan chat dengan kami?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button variant='ghost'  colorScheme='blue' mr={3} onClick={onClose}>
              Tutup
            </Button>
            <Button colorScheme='red' onClick={() => handlerResolveChat()}>Selesaikan</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default InputMessage;
