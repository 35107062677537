import {
  actionResolveMessage,
  actionSaveMessage,
  actionsUpdateStateStatusConnect,
  actionsUpdateStateTransfer,
  changeType
} from './CLientUpdateState';

const ClientListens = (Socket) => {
  Socket.on('client.chat.ongoing', (response) => {
    actionsUpdateStateStatusConnect(response);
  });

  Socket.on('client.chat.transferresult', (response) => {
    actionsUpdateStateTransfer(response);
  });

  Socket.on('client.room.joinresult', (response) => {
    changeType();
    actionsUpdateStateStatusConnect(response);
  });

  /* listen message from agent */
  Socket.on('message', (response) => {
    actionSaveMessage(response);
  });

  Socket.on('logoutresult', (response) => {});

  Socket.on('client.chat.endresult', (response) => {
    actionResolveMessage(response);
  });

  /* list history chat */
  Socket.on('client.chat.resolve', (response) => {});

  Socket.on('rating.updateresult', (response) => {});

  Socket.on('rating.updateresult', (response) => {});
};
export default ClientListens;
