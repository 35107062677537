import { Box } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendMessage } from '../../WebSocket/Clients/ClientActions';
import 'react-toastify/dist/ReactToastify.css';
import { changeStatusAlertRunned, changeVolumeAlert, layoutSetupSelector } from '../../../app/Layouts/LayoutSlice';
import { messageSelector } from '../../../app/Message/MessageSlice';
import { authSelector } from '../../../app/Auth/AuthSlice';
import { faqSelector, changeStatusAnswered, changeStatusRelated } from '../../../app/FAQ/FaqSlice';
import {
  selectSelector,
  updateNewMessage,
} from '../../../app/Select/SelectSlice';
import Chat from '../Chat/Chat';
import Form from '../Forms/Form';
import Home from '../Home/Home';

function BodyCards() {
  const { feature, bodyMaxH, bodyMinH } = useSelector(layoutSetupSelector);
  const { isOpenEmoji } = useSelector(messageSelector);
  const { OnloadImage, originCountImgData, newMessage } = useSelector(selectSelector);
  const { typeConversation, clientSessions } = useSelector(authSelector);
  const { questions, answered, pickTopic, topic, related } = useSelector(faqSelector);
  const dispatch = useDispatch();

  const scrollToBottom = () => {
    const el = document.getElementById('container-chat');
    if (Boolean(el)) {
      el.scrollTop = el.scrollHeight;
    }
  };

  const scrollToTop = () => {
    const el = document.getElementById('container-chat');
    if (Boolean(el)) {
      el.scrollTop = 0;
    }
  };

  useEffect(() => {
    if (['chat', 'rate_form'].includes(feature)) {
      if (originCountImgData.length > 0) {
        if (OnloadImage.length === originCountImgData.length) {
          scrollToBottom();
        } else {
          if (newMessage) {
            scrollToBottom();
            dispatch(updateNewMessage(true));
          } else {
            scrollToTop();
          }
        }
      } else {
        scrollToBottom();
      }
    }
  }, [feature, originCountImgData, OnloadImage, newMessage]);

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 1000);
  }, [isOpenEmoji]);

  useEffect(() => {
    if(typeConversation.type === 'faq' && feature === 'chat' && !answered && !pickTopic && !related.status && related.type === null){
      //initial data
      let message;
      let matchingAnswer;
      let matchingRelatedQuestion;

      //function to find answer
      const findAnswerByQuestionId = (stateData, questionId) => {
        for (const question of stateData) {
          const answer = question.answers.find(answer => parseInt(answer.question_id) === parseInt(questionId));
          if (answer) {
            return answer;
          }
        }
        return null;
      };

      //function to find list related_question
      const findRelatedQuestionsByQuestionId = (stateData, questionId) => {
        const question = stateData.find(question => parseInt(question.id) === parseInt(questionId));
        if (question) {
          return question.related_questions;
        }
        return null;
      };

      //condition to input message
      if(topic === "Billing"){
        matchingAnswer = findAnswerByQuestionId(questions.billingQuestion.data, typeConversation.question_id);
        matchingRelatedQuestion = findRelatedQuestionsByQuestionId(questions.billingQuestion.data, typeConversation.question_id);
        message = matchingAnswer.answer
      }else if(topic === "Domain"){
        matchingAnswer = findAnswerByQuestionId(questions.domainQuestion.data, typeConversation.question_id);
        matchingRelatedQuestion = findRelatedQuestionsByQuestionId(questions.domainQuestion.data, typeConversation.question_id);
        message = matchingAnswer.answer
      }else if(topic === "Hosting"){
        matchingAnswer = findAnswerByQuestionId(questions.hostingQuestion.data, typeConversation.question_id);
        matchingRelatedQuestion = findRelatedQuestionsByQuestionId(questions.hostingQuestion.data, typeConversation.question_id);
        message = matchingAnswer.answer
      }else if(topic === "Teknis"){
        matchingAnswer = findAnswerByQuestionId(questions.teknisQuestion.data, typeConversation.question_id);
        matchingRelatedQuestion = findRelatedQuestionsByQuestionId(questions.teknisQuestion.data, typeConversation.question_id);
        message = matchingAnswer.answer
      }else if(topic === "Other"){
        matchingAnswer = findAnswerByQuestionId(questions.otherQuestion.data, typeConversation.question_id);
        matchingRelatedQuestion = findRelatedQuestionsByQuestionId(questions.otherQuestion.data, typeConversation.question_id);
        message = matchingAnswer.answer
      }else if(topic === "Bikin Website"){
        matchingAnswer = findAnswerByQuestionId(questions.bikinWebsiteQuestion.data, typeConversation.question_id);
        matchingRelatedQuestion = findRelatedQuestionsByQuestionId(questions.bikinWebsiteQuestion.data, typeConversation.question_id);
        message = matchingAnswer.answer
      }
      
      //action dispatch
      setTimeout(() => {
        if(matchingRelatedQuestion.length === 0){
          dispatch(changeStatusAnswered(true));
        }else{
          dispatch(changeStatusRelated({status: true, data: matchingRelatedQuestion, type: null}));
        }
        sendMessage(clientSessions.chatId, message, {}, "virtual", typeConversation.type)
        dispatch(updateNewMessage(true));
        dispatch(changeVolumeAlert(1))
        dispatch(changeStatusAlertRunned(true))
      }, 500);
    }
  }, [typeConversation.type, typeConversation.question_id, feature, answered])

  return (
    <Box
      id="container-chat"
      w="100%"
      boxSizing="border-box"
      bg="bgColor"
      flexGrow={12}
      maxHeight={bodyMaxH}
      minHeight={bodyMinH}
      overflowX="hidden"
      overflowY="auto"
      css={{
        '&::-webkit-scrollbar': {
          width: '6px',
          cursor: 'pointer !important',
        },
        '&::-webkit-scrollbar-track': {
          width: '1px',
          cursor: 'pointer !important',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#D9D9D9',
          borderRadius: '24px',
          cursor: 'pointer !important',
        },
      }}
    >
      {feature === 'home' && <Home />}
      {['chat', 'rate_form'].includes(feature) && <Chat />}
      {feature === 'form_message' && <Form />}
    </Box>
  );
}

export default BodyCards;
