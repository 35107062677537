import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getTopics, getDepartments, getSocialMedia } from './SelectAPI';

const initialState = {
  topicList: [],
  departmentList: [],
  socialMediaList: [],
  OnloadImage: [],
  originCountImgData: [],
  newMessage: false,
};

/* get topics */
export const getListTopics = createAsyncThunk(
  'selectSetup/getListTopics',
  async (apiKey) => {
    const response1 = await getTopics(apiKey);
    const response = response1.data;
    return response;
  }
);

/* get departments */
export const getListDepartments = createAsyncThunk(
  'selectSetup/getListDepartments',
  async (apiKey) => {
    const response1 = await getDepartments(apiKey);
    const response = response1.data;
    return response;
  }
);

/* get social media list */
export const getSocialMediaList = createAsyncThunk(
  'selectSetup/getSocialMediaList',
  async (apiKey) => {
    const response1 = await getSocialMedia(apiKey);
    const response = response1.data;
    return response;
  }
);

export const SelectSlice = createSlice({
  name: 'selectSetup',
  initialState,
  reducers: {
    clearListTopic: (state) => {
      state.topicList = [];
    },
    clearDepartmentList: (state) => {
      state.departmentList = [];
    },
    updateOnloadImage: (state, action) => {
      state.OnloadImage.push(action.payload);
    },
    clearOnloadImage: (state) => {
      state.OnloadImage = [];
    },
    updateOriginImage: (state, action) => {
      state.originCountImgData = action.payload;
    },
    clearOriginImage: (state) => {
      state.originCountImgData = [];
    },
    updateNewMessage: (state, action) => {
      state.newMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListTopics.fulfilled, (state, action) => {
        const resData = action.payload.data;
        state.topicList = resData;
      })
      .addCase(getListTopics.rejected, (state) => {
        state.topicList = [];
      })
      .addCase(getListDepartments.fulfilled, (state, action) => {
        const resData = action.payload.data;
        state.departmentList = resData;
      })
      .addCase(getListDepartments.rejected, (state) => {
        state.departmentList = [];
      })
      .addCase(getSocialMediaList.fulfilled, (state, action) => {
        const resData = action.payload.data;
        state.socialMediaList = resData;
      })
      .addCase(getSocialMediaList.rejected, (state) => {
        state.socialMediaList = [];
      });
  },
});

export const selectSelector = (state) => state.selectSetup;
export const {
  clearDepartmentList,
  clearListTopic,
  updateOnloadImage,
  clearOnloadImage,
  updateOriginImage,
  clearOriginImage,
  updateNewMessage,
} = SelectSlice.actions;
export default SelectSlice.reducer;
