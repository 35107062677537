import { 
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box, 
  Button, 
  HStack, 
  Image, 
  Text,
  Spinner 
} from '@chakra-ui/react';
import React from 'react';
import csIllustration from '../../../assets/images/illustration/customer-service.svg';
import { useDispatch } from 'react-redux';
import {
  updateFeatureActive,
  updateFeatureBefore,
} from '../../../app/Layouts/LayoutSlice';
import { clearFaq } from '../../../app/FAQ/FaqSlice'

function Home() {
  const dispatch = useDispatch();

  const handlerButtonUpdateFeature = () => {
    dispatch(clearFaq());
    dispatch(updateFeatureBefore('home'));
    dispatch(updateFeatureActive('form_message'));
  };

  return (
    <>
      <Box
        m="3"
        bg="whitePrimary"
        flexGrow={12}
        minHeight="2em"
        borderRadius="8px"
        borderStyle="solid"
        borderWidth="1px"
        boxShadow="sm"
        borderColor="lightGray"
        padding={3}
        color="darkPrimary"
      >
        <Text fontSize="sm" fontWeight="semibold" textShadow="none" m={0}>
          Mulai chat di sini
        </Text>
        <HStack spacing="14px" mx="5" mt="3">
          <Box mr="1">
            <Image
              m={0}
              boxSize="100%"
              src={csIllustration}
              alt="thumbnail-logo"
              loading="lazy"
            />
          </Box>
          <Box>
            <Text m={0} fontSize="sm" fontWeight="normal" textShadow="none">
              Pesan Anda akan kami balas secepatnya!
            </Text>
          </Box>
        </HStack>
        <Button
          type="button"
          size="sm"
          variant="btn-submit"
          mt="5"
          cursor="pointer"
          onClick={() => handlerButtonUpdateFeature()}
        >
          Hubungkan
        </Button>
      </Box>
    </>
  );
}

export default Home;
