import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchDataPayment, fetchFormData, fetchQuestion, fetchSearchHosting } from './FaqApi';

const initialState = {
    questions:{
        billingQuestion: {
            data: null,
            currentPage: 1,
            totalPages: 1,
            itemsPerPage: 5,
            searchResults: [],
            status: 'idle',
        },
        domainQuestion: {
            data: null,
            currentPage: 1,
            totalPages: 1,
            itemsPerPage: 5,
            searchResults: [],
            status: 'idle',
        },
        hostingQuestion: {
            data: null,
            currentPage: 1,
            totalPages: 1,
            itemsPerPage: 5,
            searchResults: [],
            status: 'idle',
        },
        otherQuestion: {
            data: null,
            currentPage: 1,
            totalPages: 1,
            itemsPerPage: 5,
            searchResults: [],
            status: 'idle',
        },
        teknisQuestion: {
            data: null,
            currentPage: 1,
            totalPages: 1,
            itemsPerPage: 5,
            searchResults: [],
            status: 'idle',
        },
        bikinWebsiteQuestion: {
            data: null,
            currentPage: 1,
            totalPages: 1,
            itemsPerPage: 5,
            status: 'idle',
        },
        status:'idle'
    },
    hostings:{
        status: 'idle',
        message:null,
        hosting:[],
        similar_hosting: [],
    },
    payments:{
        data: null,
        status: 'idle'
    },
    formData:{
        status:'idle',
        data: null,  
    },
    related:{
        status: false,
        data: [],
        type: null,
    },
    answered: false,
    pickTopic: false,
    topic: 'General',
    status: 'idle',
};

export const getQuestions = createAsyncThunk(
    'faqSetup/getQuestion',
    async (category) => {
        const response = await fetchQuestion(category);
        return response;
    }
);

export const getFormData = createAsyncThunk(
	'faqSetup/getFormData',
	async () => {
		const response = fetchFormData()
		return response;
	}
)

export const getDataPayment = createAsyncThunk(
	'faqSetup/getDataPayment',
	async () => {
		const response = fetchDataPayment()
		return response;
	}
)

export const searchHosting = createAsyncThunk(
	'faqSetup/searchHosting',
	async (data) => {
		const response = fetchSearchHosting(data)
		return response;
	}
)

export const searchQuestion = (searchQuery, category) => (dispatch, getState) => {
    if(category === 'Billing'){
        const { data } = getState().faqSetup.questions.billingQuestion;
        const filteredResults = data.filter((faq) =>
            faq.question.toLowerCase().includes(searchQuery.toLowerCase())
        );
    
        // Dispatch an action to update the search results
        dispatch(setBillingSearchResults(filteredResults));
    }else if(category === 'Domain'){
        const { data } = getState().faqSetup.questions.domainQuestion;
        const filteredResults = data.filter((faq) =>
            faq.question.toLowerCase().includes(searchQuery.toLowerCase())
        );
    
        // Dispatch an action to update the search results
        dispatch(setDomainSearchResults(filteredResults));
    }else if(category === 'Hosting'){
        const { data } = getState().faqSetup.questions.hostingQuestion;
        const filteredResults = data.filter((faq) =>
            faq.question.toLowerCase().includes(searchQuery.toLowerCase())
        );
    
        // Dispatch an action to update the search results
        dispatch(setHostingSearchResults(filteredResults));
    }else if(category === 'Teknis'){
        const { data } = getState().faqSetup.questions.teknisQuestion;
        const filteredResults = data.filter((faq) =>
            faq.question.toLowerCase().includes(searchQuery.toLowerCase())
        );
    
        // Dispatch an action to update the search results
        dispatch(setTeknisSearchResults(filteredResults));
    }else if(category === 'Other'){
        const { data } = getState().faqSetup.questions.otherQuestion;
        const filteredResults = data.filter((faq) =>
            faq.question.toLowerCase().includes(searchQuery.toLowerCase())
        );
    
        // Dispatch an action to update the search results
        dispatch(setOtherSearchResults(filteredResults));
    }else if(category === 'Bikin Website'){
        const { data } = getState().faqSetup.questions.bikinWebsiteQuestion;
        const filteredResults = data.filter((faq) =>
            faq.question.toLowerCase().includes(searchQuery.toLowerCase())
        );
    
        // Dispatch an action to update the search results
        dispatch(setBikinWebsiteSearchResults(filteredResults));
    }
    
};

export const FaqSlice = createSlice({
    name: 'faqSetup',
    initialState,
    reducers: {
        changeStatusAnswered: (state, action) => {
            state.answered = action.payload;
        },
        changeStatusRelated: (state, action) => {
            state.related = action.payload;
        },
        changeListHostings: (state) => {
            state.hostings.hosting = [];
            state.hostings.similar_hosting = [];
            state.hostings.message = null;
        },
        updateTopic: (state, action) => {
            state.topic = action.payload;
        },
        clearFaq: () => {
			return {
				...initialState,
			}
		},
        updateStatusPickTopic: (state, action) => {
            state.pickTopic = action.payload;
        },
        updateRelatedType: (state, action) => {
            state.related.type = action.payload;
        },
        updateCurrentPage: (state, action) => {
            const { category, currentPage } = action.payload;
            if(category === "billing"){
                state.questions.billingQuestion.currentPage = currentPage;
            }else if(category === "domain"){
                state.questions.domainQuestion.currentPage = currentPage;
            }else if(category === "hosting"){
                state.questions.hostingQuestion.currentPage = currentPage;
            }else if(category === "teknis"){
                state.questions.teknisQuestion.currentPage = currentPage;
            }else if(category === "other"){
                state.questions.otherQuestion.currentPage = currentPage;
            }else if(category === "bikin website"){
                state.questions.bikinWebsiteQuestion.currentPage = currentPage;
            }
        },
        setBillingSearchResults: (state, action) => {
            state.questions.billingQuestion.searchResults = action.payload;
        },
        setDomainSearchResults: (state, action) => {
            state.questions.domainQuestion.searchResults = action.payload;
        },
        setHostingSearchResults: (state, action) => {
            state.questions.hostingQuestion.searchResults = action.payload;
        },
        setTeknisSearchResults: (state, action) => {
            state.questions.teknisQuestionQuestion.searchResults = action.payload;
        },
        setOtherSearchResults: (state, action) => {
            state.questions.otherQuestionQuestion.searchResults = action.payload;
        },
        setBikinWebsiteSearchResults: (state, action) => {
            state.questions.bikinWebsiteQuestion.searchResults = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getQuestions.pending, (state) => {
                state.questions.status = 'pending';
            })
            .addCase(getQuestions.fulfilled, (state, action) => {
                const category = action.payload.category;
                const resData = action.payload.data;
                if(category === "billing"){
                    state.questions.billingQuestion.data = resData;
                    state.questions.billingQuestion.totalPages = Math.ceil(resData.length / state.questions.billingQuestion.itemsPerPage);
                }else if(category === "domain"){
                    state.questions.domainQuestion.data = resData;
                    state.questions.domainQuestion.totalPages = Math.ceil(resData.length / state.questions.domainQuestion.itemsPerPage);
                }else if(category === "hosting"){
                    state.questions.hostingQuestion.data = resData;
                    state.questions.hostingQuestion.totalPages = Math.ceil(resData.length / state.questions.hostingQuestion.itemsPerPage);
                }else if(category === "teknis"){
                    state.questions.teknisQuestion.data = resData;
                    state.questions.teknisQuestion.totalPages = Math.ceil(resData.length / state.questions.teknisQuestion.itemsPerPage);
                }else if(category === "other"){
                    state.questions.otherQuestion.data = resData;
                    state.questions.otherQuestion.totalPages = Math.ceil(resData.length / state.questions.otherQuestion.itemsPerPage);
                }else if(category === "bikin website"){
                    state.questions.bikinWebsiteQuestion.data = resData;
                    state.questions.bikinWebsiteQuestion.totalPages = Math.ceil(resData.length / state.questions.bikinWebsiteQuestion.itemsPerPage);
                }
                state.questions.status = 'idle';
            })
            .addCase(getQuestions.rejected, (state) => {
                state.questions.status = 'rejected';
            })
            .addCase(getFormData.pending, (state) => {
                state.formData.status = 'pending';
            })
            .addCase(getFormData.fulfilled, (state, action) => {
                state.formData.data = action.payload.data;
                state.formData.status = 'idle';
            })
            .addCase(getFormData.rejected, (state) => {
                state.formData.status = 'rejected';
            })
            .addCase(searchHosting.pending, (state) => {
                state.hostings.status = 'pending';
            })
            .addCase(searchHosting.fulfilled, (state, action) => {
                state.hostings.hosting = action.payload.data.hostings;
                state.hostings.similar_hosting = action.payload.data.similar_hostings;
                state.hostings.message = action.payload.message;
                state.hostings.status = 'idle';
            })
            .addCase(searchHosting.rejected, (state) => {
                state.hostings.status = 'rejected';
            })
            .addCase(getDataPayment.pending, (state) => {
                state.payments.status = 'pending';
            })
            .addCase(getDataPayment.fulfilled, (state, action) => {
                state.payments.data = action.payload.data;
                state.payments.status = 'idle';
            })
            .addCase(getDataPayment.rejected, (state) => {
                state.payments.status = 'rejected';
            })
    },
});

export const faqSelector = (state) => state.faqSetup;
export const {
    changeStatusAnswered,
    changeStatusRelated,
    changeListHostings,
    updateTopic,
    clearFaq,
    updateStatusPickTopic,
    updateRelatedType,
    updateCurrentPage,
    setBillingSearchResults,
    setDomainSearchResults,
    setBikinWebsiteSearchResults,
    setTeknisSearchResults,
    setHostingSearchResults,
    setOtherSearchResults
} = FaqSlice.actions;
export default FaqSlice.reducer;