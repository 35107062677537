export function fetchQuestion(category) {
  return fetch(process.env.REACT_APP_FAQ_ENDPOINT + "/api/faqs/" + category)
    .then(response => response.json())
    .catch((error) => {
      console.log(error);
    });
}

export function fetchFormData() {
  return fetch(process.env.REACT_APP_FAQ_ENDPOINT + "/api/form-hosting")
    .then(response => response.json())
    .catch((error) => {
      console.log(error);
    })
}

export function fetchDataPayment() {
  return fetch(process.env.REACT_APP_FAQ_ENDPOINT + "/api/payment-methods")
    .then(response => response.json())
    .catch((error) => {
      console.log(error);
    })
}

export function fetchSearchHosting(formData) {
  return fetch(process.env.REACT_APP_FAQ_ENDPOINT + "/api/search-hosting", {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formData),
  })
  .then(response => response.json())
  .catch((error) => {
    console.log(error);
  });
}