import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { refreshAuth } from '../../common/WebSocket/Clients/ClientActions';
import { getListFaq, loginClient } from './AuthAPI';

const initialState = {
  idUserCompany: null,
  userCompanyName: null,
  status: 'not_active',
  uuid: null,
  apiKey: null,
  clientSessions: {},
  agentSessions: {},
  isLoaderButtonLogin: false,
  typeConversation:{},

  /* please read on readme.md */
  statusConnect: 'not_action',
};

/* login client */
export const clientLogin = createAsyncThunk(
  'authSetup/clientLogin',
  async (data) => {
    const response1 = await loginClient(data);
    const response = response1.data;
    if (response1.status === 200) {
      refreshAuth(data);
    }
    return response;
  }
);

/* List Faq */
export const listFaq = createAsyncThunk(
  'authSetup/listFaq',
  async () => {
    const datas = await getListFaq();
    const response = datas.data.data;
    return response;
  }
);

export const AuthSlice = createSlice({
  name: 'authSetup',
  initialState,
  reducers: {
    removeAuthValidate: (state) => {
      state.idUserCompany = null;
      state.userCompanyName = null;
      state.status = 'not_active';
      state.uuid = null;
      state.apiKey = null;
    },
    updateDataAuth: (state, action) => {
      const dataAuth = action.payload;
      state.uuid = dataAuth.uuid;
      state.status = dataAuth.status_name;
      state.idUserCompany = dataAuth.id;
      state.userCompanyName = dataAuth.name;
    },
    updateApiKey: (state, action) => {
      state.apiKey = action.payload;
    },
    updateStatusConnect: (state) => {
      state.isLoaderButtonLogin = false;
      state.statusConnect = 'connected';
    },
    updateStatusFromAction: (state, action) => {
      state.statusConnect = action.payload;
    },
    updateSessionsClient: (state, action) => {
      state.clientSessions = action.payload;
    },
    updateSessionsAgent: (state, action) => {
      state.agentSessions = action.payload;
    },
    updateTypeConversation: (state, action) => {
      state.typeConversation = action.payload;
    },
    clearAllSessionsFromResolveChat: (state) => {
      state.clientSessions = {};
      state.agentSessions = {};
      state.isLoaderButtonLogin = false;
      state.statusConnect = 'not_action';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(clientLogin.pending, (state) => {
        state.isLoaderButtonLogin = true;
        state.statusConnect = 'pending';
        state.clientSessions = {};
      })
      .addCase(clientLogin.fulfilled, (state, action) => {
        const dataRes = action.payload.data;
        const session = {
          companyUuid: dataRes.company_uuid,
          companyName: dataRes.company_name,
          departmentId: dataRes.department_id,
          departmentName: dataRes.department_name,
          emailClient: dataRes.email,
          nameClient: dataRes.name,
          topicId: dataRes.topic_id,
          topicName: dataRes.topic_name,
          chatId: '',
          room: '',
          channelName: '',
          channelId: '',
        };
        const type = {
          question_id: dataRes.question_id,
          type: dataRes.type
        }
        state.typeConversation = type;
        state.clientSessions = session;
        state.statusConnect = 'integrate_socket';
      })
      .addCase(clientLogin.rejected, (state) => {
        state.statusConnect = 'rejected';
      })
      .addCase(listFaq.pending, (state) => {
        state.statusFaq = 'pending';
      })
      .addCase(listFaq.fulfilled, (state, action) => {
        state.faqs = action.payload
        state.statusFaq = 'idle';
      })
      .addCase(listFaq.rejected, (state) => {
        state.statusFaq = 'rejected';
      })
  },
});

export const authSelector = (state) => state.authSetup;
export const {
  removeAuthValidate,
  updateDataAuth,
  updateApiKey,
  updateStatusConnect,
  updateSessionsClient,
  updateSessionsAgent,
  clearAllSessionsFromResolveChat,
  updateStatusFromAction,
  updateTypeConversation
} = AuthSlice.actions;
export default AuthSlice.reducer;
