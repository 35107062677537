import { VStack, Box, Text, FormControl, FormLabel, SkeletonText,  Circle } from '@chakra-ui/react';
import { ArrowForwardIcon, ArrowBackIcon } from '@chakra-ui/icons';
import Select from 'react-select'
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { layoutSetupSelector, changeStatusAlertRunned, changeVolumeAlert } from '../../../app/Layouts/LayoutSlice';
import { messageSelector } from '../../../app/Message/MessageSlice';
import { updateOriginImage, updateNewMessage } from '../../../app/Select/SelectSlice';
import { sendMessage } from '../../WebSocket/Clients/ClientActions';
import { authSelector, updateTypeConversation } from '../../../app/Auth/AuthSlice';
import { 
  faqSelector, 
  changeStatusAnswered, 
  changeStatusRelated, 
  getFormData, 
  searchHosting, 
  changeListHostings, 
  getDataPayment, 
  getQuestions, 
  updateTopic,
  updateStatusPickTopic,
  updateRelatedType, 
  updateCurrentPage} from '../../../app/FAQ/FaqSlice';
import { isEmptyObj } from '../../Utils/helpers';
// import FormUpload from '../FormUpload/FormUpload';
import BubbleChat from './BubbleChat/BubbleChat';
import RatingChat from './RatingChat/RatingChat';
import FaqComponent from '../FaqCard/FaqComponent';

function Chat() {
  const { deviceVersion } = useSelector(layoutSetupSelector);
  const { listBubbleChat } = useSelector(messageSelector);
  const { clientSessions, typeConversation } = useSelector(authSelector);
  const { answered, related, questions, formData, hostings, payments, topic } = useSelector(faqSelector)
  const [objCustomize, setObjCustomize] = useState({});
  const [isContentVisible, setContentVisible] = useState(false);
  const [isSearchDomain, setSearchDomain] = useState(false);
  const [isSearchHosting, setSearchHosting] = useState(false);
  const [isSearchPayment, setSearchPayment] = useState(false);
  const [extensions, setExtensions] = useState([])
  const [extension, setExtension] = useState('')
  const [price, setPrice] = useState('')
  const [renewal, setRenewal] = useState('')
  const [syarat, setSyarat] = useState('')
  const [panel, setPanel] = useState('')
  const [akun, setAkun] = useState('')
  const [payment, setPayment] = useState('')
  const [paymentName, setPaymentName] = useState('')
  const [kapasitas, setKapasitas] = useState('')
  const [tipeHosting, setTipeHosting] = useState('')
  const [pickTopic, setPickTopic] = useState(false)
  const [isSyaratDomain, setIsSyaratDomain] = useState(false)
  const dispatch = useDispatch();

  const topicList = [
    {id: 1, topic: 'Billing'}, 
    {id: 2, topic: 'Domain'}, 
    {id: 3, topic: 'Hosting'}, 
    {id: 4, topic:'Teknis'}, 
    {id: 5, topic: 'Other'},
    {id: 6, topic: 'Bikin Website'},
    ]

  let panelOption;
  let akunOption;
  let kapasitasOption;
  let tipeOption;
  let paymentOptions;

  const RenderBubbleChat = () => {
    const isStat = isEmptyObj(listBubbleChat);

    if (Boolean(isStat)) {
      return <></>;
    }

    return listBubbleChat.map((value, key) => {
      if (value.is_netral) {
        return <RatingChat key={key} />;
      } else {
        if (Boolean(value.is_sender)) {
          return (
            <BubbleChat
              key={key}
              data={value}
              parentProps={{
                justifyContent: 'right',
                m: '0.5rem 1rem 0.5rem auto !important',
                ...objCustomize,
              }}
              childProps={{
                bg: 'primary',
                color: 'whitePrimary',
                w: '100%',
                minHeight: '2.5rem',
                borderRadius: '15px 0px 15px 15px',
              }}
              labelProps={{
                fontSize: 'sm',
                fontWeight: 'normal',
                textAlign: 'right',
                mb: '5px',
                color: 'darkGray',
              }}
            />
          );
        } else {
          return (
            <BubbleChat
              key={key}
              data={value}
              parentProps={{
                justifyContent: 'left',
                m: '0.5rem auto 0.5rem 1rem !important',
                ...objCustomize,
              }}
              childProps={{
                bg: 'whitePrimary',
                color: 'darkPrimary',
                border: '1px',
                borderColor: 'lightGray',
                borderStyle: 'solid',
                w: '100%',
                minHeight: '2.5rem',
                borderRadius: '0px 15px 15px 15px',
              }}
              labelProps={{
                fontSize: 'sm',
                fontWeight: 'normal',
                mb: '5px',
                color: 'darkGray',
                noOfLines: 1,
              }}
            />
          );
        }
      }
    });
  };

  const handlerSendFaq = (e) => {
    setContentVisible(!isContentVisible);
    dispatch(updateTypeConversation({type: 'faq', question_id:e.target.id}))
    sendMessage(clientSessions.chatId, e.target.value, {}, null, typeConversation.type)
    dispatch(changeStatusAnswered(false))
  }

  const handlerSendRelated = (e) => {
    const relatedType = e.currentTarget.getAttribute('relatedtype');

    if(relatedType === 'general'){
      setContentVisible(false);
      sendMessage(clientSessions.chatId, e.target.value, {}, null, typeConversation.type)
      dispatch(changeStatusRelated({status: false, data: [], type:null}))
  
      //action dispatch
      setTimeout(() => {
        sendMessage(clientSessions.chatId, e.target.id, {}, "virtual", typeConversation.type)
        dispatch(updateNewMessage(true));
        dispatch(changeVolumeAlert(1)) 
        dispatch(changeStatusAlertRunned(true))
        dispatch(changeStatusAnswered(true))
      }, 500);
    }else if(relatedType === 'api'){
      if(topic === "Domain"){
        sendMessage(clientSessions.chatId, e.target.value, {}, null, typeConversation.type)
        dispatch(changeStatusRelated({status: false, data: [], type:'api'}))
    
        //action dispatch
        setTimeout(() => {
          sendMessage(clientSessions.chatId, e.target.id, {}, "virtual", typeConversation.type)
          setSearchDomain(true);
          dispatch(updateNewMessage(true));
          dispatch(changeVolumeAlert(1))
          dispatch(changeStatusAlertRunned(true))
        }, 500);
      }else if(topic === "Hosting"){
        dispatch(getFormData())
        sendMessage(clientSessions.chatId, e.target.value, {}, null, typeConversation.type)
        dispatch(changeStatusRelated({status: false, data: [], type:'api'}))
    
        //action dispatch
        setTimeout(() => {
          sendMessage(clientSessions.chatId, e.target.id, {}, "virtual", typeConversation.type)
          setSearchHosting(true);
          dispatch(updateNewMessage(true));
          dispatch(changeVolumeAlert(1))
          dispatch(changeStatusAlertRunned(true))
        }, 500);
      }else if(topic === "Billing"){
        dispatch(getDataPayment())
        sendMessage(clientSessions.chatId, e.target.value, {}, null, typeConversation.type)
        dispatch(changeStatusRelated({status: false, data: [], type:'api'}))
    
        // action dispatch
        setTimeout(() => {
          sendMessage(clientSessions.chatId, e.target.id, {}, "virtual", typeConversation.type)
          setSearchPayment(true);
          dispatch(updateNewMessage(true));
          dispatch(changeVolumeAlert(1))
          dispatch(changeStatusAlertRunned(true))
        }, 500);
      }
    }
    
  }

  const handleExtension = (e) => {
		if (!e) {
			setExtension('')
			return
		}
		setExtension(e.value)
		setPrice(e.price)
		setRenewal(e.renewal)
		setSyarat(e.syarat)
	}

  const handlePayment = (e) => {
		if (!e) {
			setPayment('')
			return
		}
		setPayment(e.value)
		setPaymentName(e.nama)
	}

  const handlerShowHide = () => {
    setContentVisible(!isContentVisible);
  }

  const handlerSearchExtension = () => {
    setSearchDomain(false);
    dispatch(updateRelatedType(null));
    sendMessage(clientSessions.chatId, `Ekstensi ${extension}`, {}, null, typeConversation.type)
    const message = `Harga untuk domain dengan ekstensi ${extension} adalah sebagai berikut:\n\nHarga Register = Rp ${price}\nHarga Renewal = Rp ${renewal}\n\nAnda dapat melihat harga untuk ekstensi yang lain melalui link berikut: https://www.qwords.com/domain-name/`;

    //action dispatch
    setTimeout(() => {
      sendMessage(clientSessions.chatId, message, {}, "virtual", typeConversation.type)
      dispatch(updateNewMessage(true));
      dispatch(changeVolumeAlert(1))
      dispatch(changeStatusAlertRunned(true))
    }, 1000);

    if(syarat !== ''){
      setTimeout(() => {
        sendMessage(clientSessions.chatId, `Ekstensi domain ini memiliki syarat pembelian. Apakah Anda ingin melihat syarat pembelian domain ini ?`, {}, "virtual", typeConversation.type)
        dispatch(updateNewMessage(true));
        dispatch(changeVolumeAlert(1))
        dispatch(changeStatusAlertRunned(true))
        setIsSyaratDomain(true)
      }, 1500);
    }else{
      dispatch(changeStatusAnswered(true))
    }
  }

  const handlerSearchPayment = () => {
    setSearchPayment(false);
    dispatch(updateRelatedType(null));
    sendMessage(clientSessions.chatId, `Metode Pembayaran: ${paymentName}`, {}, null, typeConversation.type)
    const message = `Anda dapat melihat cara pembayaran untuk metode pembayaran ${paymentName} pada link berikut: ${payment}`;

    //action dispatch
    setTimeout(() => {
      sendMessage(clientSessions.chatId, message, {}, "virtual", typeConversation.type)
      dispatch(updateNewMessage(true));
      dispatch(changeVolumeAlert(1))
      dispatch(changeStatusAlertRunned(true))
      dispatch(changeStatusAnswered(true))
    }, 1000);
  }

  const handlerSyarat = (e) => {
    sendMessage(clientSessions.chatId, e.target.value, {}, null, typeConversation.type)
    if(e.target.id === 'syarat'){
      setIsSyaratDomain(false)
      let message;
      if(syarat === "Silakan buka Halaman Syarat Domain .ID"){
        message = `${syarat}\n\n Melalui link berikut: https://www.qwords.com/master-service-agreement/?tab-content=syarat-domain-id`
      }else{
        message = `${syarat}`
      }
      
      setTimeout(() => {
        sendMessage(clientSessions.chatId, message, {}, "virtual", typeConversation.type)
        dispatch(changeStatusAnswered(true))
        dispatch(updateNewMessage(true));
        dispatch(changeVolumeAlert(1))
        dispatch(changeStatusAlertRunned(true))
      }, 1000);
    }else{
      setIsSyaratDomain(false)
      dispatch(changeStatusAnswered(true))
    }
  }

  const handlerSearchHosting = () => {
    dispatch(updateRelatedType(null));
    setSearchHosting(false)
    dispatch(searchHosting({panel: panel, akun_email: akun, kapasitas: kapasitas, tipe_hosting: tipeHosting}))
    sendMessage(clientSessions.chatId, `Cari Hosting dengan Spesifikasi Berikut:\n\nPanel: ${panel}\nAkun Email: ${akun}\nKapasitas: ${kapasitas}\nTipe Hosting: ${tipeHosting}`, {}, null, typeConversation.type)
  }

  const handlerBack = () => {
    setPickTopic(true);
    dispatch(updateStatusPickTopic(true));
    dispatch(changeStatusAnswered(false));
    dispatch(changeStatusRelated({status: false, data: [], type:null}))
  }

  const handlePageChange = (newPage) => {
    const currentTopic = topic.toLowerCase();

    if(currentTopic === 'billing'){
      if (newPage >= 1 && newPage <= questions.billingQuestion.totalPages) {
        dispatch(updateCurrentPage({ category: currentTopic, currentPage: newPage }));
      }
    }else if(currentTopic === 'domain'){
      if (newPage >= 1 && newPage <= questions.domainQuestion.totalPages) {
        dispatch(updateCurrentPage({ category: currentTopic, currentPage: newPage }));
      }
    }else if(currentTopic === 'hosting'){
      if (newPage >= 1 && newPage <= questions.hostingQuestion.totalPages) {
        dispatch(updateCurrentPage({ category: currentTopic, currentPage: newPage }));
      }
    }else if(currentTopic === 'teknis'){
      if (newPage >= 1 && newPage <= questions.teknisQuestion.totalPages) {
        dispatch(updateCurrentPage({ category: currentTopic, currentPage: newPage }));
      }
    }else if(currentTopic === 'other'){
      if (newPage >= 1 && newPage <= questions.otherQuestion.totalPages) {
        dispatch(updateCurrentPage({ category: currentTopic, currentPage: newPage }));
      }
    }else if(currentTopic === 'bikin website'){
      if (newPage >= 1 && newPage <= questions.bikinWebsiteQuestion.totalPages) {
        dispatch(updateCurrentPage({ category: currentTopic, currentPage: newPage }));
      }
    }
  };

  const handlerChangeTopic = (e) => {
    setPickTopic(false);
    dispatch(updateStatusPickTopic(false));
    if(e.target.value === "Billing"){
      dispatch(updateTopic("Billing"))
      if(questions.billingQuestion.data === null){
        dispatch(getQuestions('billing'));
      }
      dispatch(changeStatusAnswered(true));
    }else if(e.target.value === "Domain"){
      dispatch(updateTopic("Domain"))
      if(questions.domainQuestion.data === null){
        dispatch(getQuestions('domain'));
      }
      dispatch(changeStatusAnswered(true));
    }else if(e.target.value === "Hosting"){
      dispatch(updateTopic("Hosting"))
      if(questions.hostingQuestion.data === null){
        dispatch(getQuestions('hosting'));
      }
      dispatch(changeStatusAnswered(true));
    }else if(e.target.value === "Other"){
      dispatch(updateTopic("Other"))
      if(questions.otherQuestion.data === null){
        dispatch(getQuestions('other'));
      }
      dispatch(changeStatusAnswered(true));
    }else if(e.target.value === "Teknis"){
      dispatch(updateTopic("Teknis"))
      if(questions.teknisQuestion.data === null){
        dispatch(getQuestions('teknis'));
      }
      dispatch(changeStatusAnswered(true));
    }else if(e.target.value === "Bikin Website"){
      dispatch(updateTopic("Bikin Website"))
      if(questions.bikinWebsiteQuestion.data === null){
        dispatch(getQuestions('bikin website'));
      }
      dispatch(changeStatusAnswered(true));
    }
  }

  useEffect(() => {
    const dataChatImage = listBubbleChat.filter((params) => {
      return params.file_type === 'image';
    });
    dispatch(updateOriginImage(dataChatImage));
  }, [listBubbleChat]);

  useEffect(() => {
    if (['tablet'].includes(deviceVersion)) {
      setObjCustomize({
        maxW: '30%',
        minW: '15%',
      });
    } else if (['large_mobile', 'medium_mobile'].includes(deviceVersion)) {
      setObjCustomize({
        maxW: '50%',
        minW: '15%',
      });
    } else {
      setObjCustomize({
        maxW: '70%',
        minW: '15%',
      });
    }
  }, [deviceVersion]);

  useEffect(() => {
		fetch('https://www.qwords.com/api/domain.php?_type=query')
			.then((data) => data.json())
			.then((val) => setExtensions(val))
    
    dispatch(getFormData())
	}, [])

  const options = extensions.map(function (option) {
		return {value: option[1], label: option[1], price: option[4], renewal: option[3], syarat: option[5]}
	})

  if(formData.data){
    panelOption = formData.data.panels.map(function (option) {
      return {value: option, label: option}
    })
    akunOption = formData.data.akun_email.map(function (option) {
      return {value: option, label: option}
    })
    kapasitasOption = formData.data.kapasitas.map(function (option) {
      return {value: option, label: option}
    })
    tipeOption = formData.data.tipe_hosting.map(function (option) {
      return {value: option, label: option}
    })
  }

  if(payments.data){
    paymentOptions = payments.data.map(function (option) {
      return {value: option.link, label: option.name, nama: option.name}
    })
  }

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: '100%',
      fontSize: '14px',
      minWidth: '200px'
    }),
  };

  useEffect(() => {
    if(hostings.hosting.length !== 0 && !pickTopic){
      const message = `${hostings.message}. Berikut hasil pencarian Anda:\n\nNama Produk: ${hostings.hosting[0].product_name}\nPanel: ${hostings.hosting[0].panel}\nAkun Email: ${hostings.hosting[0].akun_email}\nKapasitas: ${hostings.hosting[0].kapasitas}\nTipe Hosting: ${hostings.hosting[0].tipe_hosting}\n\nAnda dapat melihat spesifikasi lengkapnya melalui link berikut: ${hostings.hosting[0].link}`
      //data for dispatch bubble chat
      
      //action dispatch
      setTimeout(() => {
        sendMessage(clientSessions.chatId, message, {}, "virtual", typeConversation.type)
        // dispatch(updateListBubbleChat(params));
        dispatch(updateNewMessage(true));
        dispatch(changeVolumeAlert(1))
        dispatch(changeStatusAlertRunned(true))
        dispatch(changeListHostings())
        dispatch(changeStatusAnswered(true))
      }, 500);
    }else if(hostings.similar_hosting.length !== 0 && !pickTopic){
      const message = `${hostings.message}.\n\nTetapi kami memiliki hosting yang serupa dengan spesifikasi yang Anda cari.`
      const message2 = `Berikut hosting yang memiliki spesifikasi serupa:\n\nNama Produk: ${hostings.similar_hosting.product_name}\nPanel: ${hostings.similar_hosting.panel}\nAkun Email: ${hostings.similar_hosting.akun_email}\nKapasitas: ${hostings.similar_hosting.kapasitas}\nTipe Hosting: ${hostings.similar_hosting.tipe_hosting}\n\nAnda dapat melihat spesifikasi lengkapnya melalui link berikut: ${hostings.similar_hosting.link}`

      //action dispatch
      setTimeout(() => {
        sendMessage(clientSessions.chatId, message, {}, "virtual", typeConversation.type)
        dispatch(updateNewMessage(true));
        dispatch(changeVolumeAlert(1))
        dispatch(changeStatusAlertRunned(true))
      }, 500);

      //action dispatch
      setTimeout(() => {
        sendMessage(clientSessions.chatId, message2, {}, "virtual", typeConversation.type)
        dispatch(updateNewMessage(true));
        dispatch(changeVolumeAlert(1))
        dispatch(changeStatusAlertRunned(true))
        dispatch(changeListHostings())
        dispatch(changeStatusAnswered(true))
      }, 1000);
    }
  }, [hostings.hosting, hostings.similar_hosting])

  useEffect(() => {
    if(related.type === 'api' && topic === 'Domain'){
      setSearchDomain(true)
    }else if(related.type === 'api' && topic === 'Hosting'){
      setSearchHosting(true)
    }else if(related.type === 'api' && topic === 'Billing'){
      setSearchPayment(true)
    }
  }, []) 


  return (
    <>
      <VStack justify="end">
        <VStack w="100%" marginBottom='1rem'>
          <RenderBubbleChat />
          {questions.status === 'pending' ? <>
            <Box padding='6' boxShadow='lg' bg='white' w='100%'>
              <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
            </Box>
          </> : <>
          {topic === 'Billing' && questions.billingQuestion.data !== null  && typeConversation.type === 'faq' && !pickTopic ? (
            <>
            {answered ? 
            <FaqComponent title='Another Questions?' questionName='billingQuestion' handlerSendFaq={handlerSendFaq} handlePageChange={handlePageChange} isBack={true} handlerBack={handlerBack}/>
            :<></>}
            {related.status === true ?
              <>
              <Box
                display='flex'
                justifyContent='start'
                flexDirection='column'
                width='100%'
              >
              {related.data.map((faq) => (
                <Box
                  ms='auto'
                >
                  <Box
                    key={faq.question_id}
                    as='button'
                    height='fit'
                    width='fit'
                    lineHeight='1.2'
                    transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
                    border='1px'
                    px='8px'
                    py='10px'
                    me='10px'
                    borderRadius='8px'
                    fontSize='12px'
                    fontWeight='normal'
                    borderColor='#ff8d0b'
                    bg="white"
                    color='#d97300'
                    textAlign='right'
                    mb='10px'
                    display='flex'
                    justifyContent='space-between'
                    onClick={handlerSendRelated}
                    value={faq.related_question}
                    id={faq.answer}
                    relatedtype={faq.type}
                    type='submit'
                    _hover={{ bg: '#ffead3', color: '#d97300' }}
                    _active={{
                      bg: '#dddfe2',
                      transform: 'scale(0.98)',
                      borderColor: '#bec3c9',
                    }}
                    _focus={{
                      boxShadow:
                        '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
                    }}
                  >
                    {faq.related_question}
                  </Box>
                </Box>
                ))}
              </Box>
              </>
            :<></>}
            {isSearchPayment ?
              <>
              <Box
                display='flex'
                justifyContent='space-between'
                width='90%'
                bg='white'
                p='10px'
                borderRadius='8px'
                border='1px solid #d9d9d9'
              >
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    onChange={handlePayment}
                    isSearchable={true}
                    name="extensions"
                    options={paymentOptions}
                    placeholder={`Pilih pembayaran`}
                    styles={customStyles}
                  />
                  <Box
                    as='button'
                    height='fit'
                    width='fit'
                    lineHeight='1.2'
                    transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
                    border='1px'
                    px='8px'
                    py='10px'
                    ms='10px'
                    borderRadius='8px'
                    fontSize='14px'
                    fontWeight='normal'
                    borderColor='#d97300'
                    bg="#d97300"
                    color='#fff'
                    textAlign='right'
                    mb='0px'
                    display='flex'
                    justifyContent='space-between'
                    type='submit'
                    onClick={handlerSearchPayment}
                    _hover={{ bg: 'white', color: '#d97300' }}
                    _active={{
                      bg: '#dddfe2',
                      transform: 'scale(0.98)',
                      borderColor: '#bec3c9',
                    }}
                    _focus={{
                      boxShadow:
                        '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
                    }}
                  >
                    Search
                  </Box>
              </Box>
              
              </>
            :<></>}
            </>
          ) : topic === 'Domain' && questions.domainQuestion.data !== null  && typeConversation.type === 'faq' && !pickTopic ? (
            <>
            {answered ? 
              <FaqComponent title='Another Questions?' questionName='domainQuestion' handlerSendFaq={handlerSendFaq} handlePageChange={handlePageChange} isBack={true} handlerBack={handlerBack}/>
            :<></>}
            {related.status === true ?
              <>
              <Box
                display='flex'
                justifyContent='start'
                flexDirection='column'
                width='100%'
              >
              {related.data.map((faq) => (
                <Box
                  ms='auto'
                >
                  <Box
                    key={faq.question_id}
                    as='button'
                    height='fit'
                    width='fit'
                    lineHeight='1.2'
                    transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
                    border='1px'
                    px='8px'
                    py='10px'
                    me='10px'
                    borderRadius='8px'
                    fontSize='12px'
                    fontWeight='normal'
                    borderColor='#ff8d0b'
                    bg="white"
                    color='#d97300'
                    textAlign='right'
                    mb='10px'
                    display='flex'
                    justifyContent='space-between'
                    onClick={handlerSendRelated}
                    value={faq.related_question}
                    id={faq.answer}
                    relatedtype={faq.type}
                    type='submit'
                    _hover={{ bg: '#ffead3', color: '#d97300' }}
                    _active={{
                      bg: '#dddfe2',
                      transform: 'scale(0.98)',
                      borderColor: '#bec3c9',
                    }}
                    _focus={{
                      boxShadow:
                        '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
                    }}
                  >
                    {faq.related_question}
                  </Box>
                </Box>
                ))}
              </Box>
              </>
            :<></>}
            {isSearchDomain ?
              <>
              <Box
                display='flex'
                justifyContent='space-between'
                width='90%'
                bg='white'
                p='10px'
                borderRadius='8px'
                border='1px solid #d9d9d9'
              >
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    onChange={handleExtension}
                    isSearchable={true}
                    name="extensions"
                    options={options}
                    placeholder={`Pilih ekstensi domain`}
                    styles={customStyles}
                  />
                  <Box
                    as='button'
                    height='fit'
                    width='fit'
                    lineHeight='1.2'
                    transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
                    border='1px'
                    px='8px'
                    py='10px'
                    ms='10px'
                    borderRadius='8px'
                    fontSize='14px'
                    fontWeight='normal'
                    borderColor='#d97300'
                    bg="#d97300"
                    color='#fff'
                    textAlign='right'
                    mb='0px'
                    display='flex'
                    justifyContent='space-between'
                    type='submit'
                    onClick={handlerSearchExtension}
                    _hover={{ bg: 'white', color: '#d97300' }}
                    _active={{
                      bg: '#dddfe2',
                      transform: 'scale(0.98)',
                      borderColor: '#bec3c9',
                    }}
                    _focus={{
                      boxShadow:
                        '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
                    }}
                  >
                    Search
                  </Box>
              </Box>
              
              </>
            :<></>}
            {isSyaratDomain ? 
              <>
                <Box
                display='flex'
                justifyContent='start'
                flexDirection='column'
                width='100%'
              >
                <Box
                  ms='auto'
                >
                  <Box
                    as='button'
                    height='fit'
                    width='fit'
                    lineHeight='1.2'
                    transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
                    border='1px'
                    px='8px'
                    py='10px'
                    me='10px'
                    borderRadius='8px'
                    fontSize='12px'
                    fontWeight='normal'
                    borderColor='#ff8d0b'
                    bg="white"
                    color='#d97300'
                    textAlign='right'
                    mb='10px'
                    display='flex'
                    justifyContent='space-between'
                    onClick={handlerSyarat}
                    id='syarat'
                    value='Syarat Domain'
                    type='submit'
                    _hover={{ bg: '#ffead3', color: '#d97300' }}
                    _active={{
                      bg: '#dddfe2',
                      transform: 'scale(0.98)',
                      borderColor: '#bec3c9',
                    }}
                    _focus={{
                      boxShadow:
                        '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
                    }}
                  >
                    Syarat Domain
                  </Box>
                  <Box
                    as='button'
                    height='fit'
                    width='fit'
                    lineHeight='1.2'
                    transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
                    border='1px'
                    px='8px'
                    py='10px'
                    borderRadius='8px'
                    fontSize='12px'
                    fontWeight='normal'
                    borderColor='#ff8d0b'
                    bg="white"
                    color='#d97300'
                    textAlign='right'
                    mb='10px'
                    display='flex'
                    justifyContent='space-between'
                    onClick={handlerSyarat}
                    value='Pertanyaan lain'
                    id='question'
                    type='submit'
                    _hover={{ bg: '#ffead3', color: '#d97300' }}
                    _active={{
                      bg: '#dddfe2',
                      transform: 'scale(0.98)',
                      borderColor: '#bec3c9',
                    }}
                    _focus={{
                      boxShadow:
                        '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
                    }}
                  >
                    Pertanyaan lain
                  </Box>
                </Box>
              </Box>
              </>
            :<></>}
            </>
          ) : topic === 'Other' && questions.otherQuestion.data !== null  && typeConversation.type === 'faq' && !pickTopic ? (
            <>
            {answered ? 
              <FaqComponent title='Another Questions?' questionName='otherQuestion' handlerSendFaq={handlerSendFaq} handlePageChange={handlePageChange} isBack={true} handlerBack={handlerBack}/>
            :<></>}
            {related.status === true ?
              <>
              <Box
                display='flex'
                justifyContent='start'
                flexDirection='column'
                width='100%'
              >
              {related.data.map((faq) => (
                <Box
                  ms='auto'
                >
                  <Box
                    key={faq.question_id}
                    as='button'
                    height='fit'
                    width='fit'
                    lineHeight='1.2'
                    transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
                    border='1px'
                    px='8px'
                    py='10px'
                    me='10px'
                    borderRadius='8px'
                    fontSize='12px'
                    fontWeight='normal'
                    borderColor='#ff8d0b'
                    bg="white"
                    color='#d97300'
                    textAlign='right'
                    mb='10px'
                    display='flex'
                    justifyContent='space-between'
                    onClick={handlerSendRelated}
                    value={faq.related_question}
                    id={faq.answer}
                    relatedtype={faq.type}
                    type='submit'
                    _hover={{ bg: '#ffead3', color: '#d97300' }}
                    _active={{
                      bg: '#dddfe2',
                      transform: 'scale(0.98)',
                      borderColor: '#bec3c9',
                    }}
                    _focus={{
                      boxShadow:
                        '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
                    }}
                  >
                    {faq.related_question}
                  </Box>
                </Box>
                ))}
              </Box>
              </>
            :<></>}
            </>
          ) : topic === 'Hosting' && questions.hostingQuestion.data !== null  && typeConversation.type === 'faq' && !pickTopic ? (
            <>
            {answered ?
              <FaqComponent title='Another Questions?' questionName='hostingQuestion' handlerSendFaq={handlerSendFaq} handlePageChange={handlePageChange} isBack={true} handlerBack={handlerBack}/>
            :<></>}
            {related.status === true ?
              <>
              <Box
                display='flex'
                justifyContent='start'
                flexDirection='column'
                width='100%'
              >
              {related.data.map((faq) => (
                <Box
                  ms='auto'
                >
                  <Box
                    key={faq.question_id}
                    as='button'
                    height='fit'
                    width='fit'
                    lineHeight='1.2'
                    transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
                    border='1px'
                    px='8px'
                    py='10px'
                    me='10px'
                    borderRadius='8px'
                    fontSize='12px'
                    fontWeight='normal'
                    borderColor='#ff8d0b'
                    bg="white"
                    color='#d97300'
                    textAlign='right'
                    mb='10px'
                    display='flex'
                    justifyContent='space-between'
                    onClick={handlerSendRelated}
                    value={faq.related_question}
                    id={faq.answer}
                    relatedtype={faq.type}
                    type='submit'
                    _hover={{ bg: '#ffead3', color: '#d97300' }}
                    _active={{
                      bg: '#dddfe2',
                      transform: 'scale(0.98)',
                      borderColor: '#bec3c9',
                    }}
                    _focus={{
                      boxShadow:
                        '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
                    }}
                  >
                    {faq.related_question}
                  </Box>
                </Box>
                ))}
              </Box>
              </>
            :<></>}
            {isSearchHosting ? <>
            <Box
              bg='white'
              border="1px solid #d9d9d9"
              p="10px"
              borderRadius="8px"
              width="90%"
            >
              <FormControl>
                <FormLabel>Jenis Panel</FormLabel>
                <Select placeholder='Pilih Panel' options={panelOption} onChange={(e) => setPanel(e.value)}/>
              </FormControl>
              <FormControl marginTop="5px">
                <FormLabel>Jumlah Akun Email</FormLabel>
                <Select placeholder='Pilih jumlah akun email' options={akunOption} onChange={(e) => setAkun(e.value)}/>
              </FormControl>
              <FormControl marginTop="5px">
                <FormLabel>Kapasitas</FormLabel>
                <Select placeholder='Pilih kapasitas' options={kapasitasOption} onChange={(e) => setKapasitas(e.value)}/>
              </FormControl>
              <FormControl marginTop="5px">
                <FormLabel>Tipe Hosting</FormLabel>
                <Select placeholder='Pilih jenis hosting' options={tipeOption} onChange={(e) => setTipeHosting(e.value)}/>
              </FormControl>
              <Box
                    as='button'
                    height='fit'
                    width='100%'
                    lineHeight='1.2'
                    transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
                    border='1px'
                    px='8px'
                    py='10px'
                    mt='20px'
                    borderRadius='8px'
                    fontSize='16px'
                    fontWeight='normal'
                    borderColor='#d97300'
                    bg="#d97300"
                    color='#fff'
                    textAlign='center'
                    mb='0px'
                    type='submit'
                    onClick={handlerSearchHosting}
                    _hover={{ bg: 'white', color: '#d97300' }}
                    _active={{
                      bg: '#dddfe2',
                      transform: 'scale(0.98)',
                      borderColor: '#bec3c9',
                    }}
                    _focus={{
                      boxShadow:
                        '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
                    }}
                  >
                    Search
              </Box>
            </Box>
            </> : <></>}
            </>
          ) : topic === 'Teknis' && questions.teknisQuestion.data !== null  && typeConversation.type === 'faq' && !pickTopic ? (
            <>
            {answered ?
              <FaqComponent title='Another Questions?' questionName='teknisQuestion' handlerSendFaq={handlerSendFaq} handlePageChange={handlePageChange} isBack={true} handlerBack={handlerBack}/>
            :<></>}
            {related.status === true ?
              <>
              <Box
                display='flex'
                justifyContent='start'
                flexDirection='column'
                width='100%'
              >
              {related.data.map((faq) => (
                <Box
                  ms='auto'
                >
                  <Box
                    key={faq.question_id}
                    as='button'
                    height='fit'
                    width='fit'
                    lineHeight='1.2'
                    transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
                    border='1px'
                    px='8px'
                    py='10px'
                    me='10px'
                    borderRadius='8px'
                    fontSize='12px'
                    fontWeight='normal'
                    borderColor='#ff8d0b'
                    bg="white"
                    color='#d97300'
                    textAlign='right'
                    mb='10px'
                    display='flex'
                    justifyContent='space-between'
                    onClick={handlerSendRelated}
                    value={faq.related_question}
                    id={faq.answer}
                    relatedtype={faq.type}
                    type='submit'
                    _hover={{ bg: '#ffead3', color: '#d97300' }}
                    _active={{
                      bg: '#dddfe2',
                      transform: 'scale(0.98)',
                      borderColor: '#bec3c9',
                    }}
                    _focus={{
                      boxShadow:
                        '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
                    }}
                  >
                    {faq.related_question}
                  </Box>
                </Box>
                ))}
              </Box>
              </>
            :<></>}
            </>
          ) : topic === 'Bikin Website' && questions.bikinWebsiteQuestion.data !== null  && typeConversation.type === 'faq' && !pickTopic ? (
            <>
            {answered ?
              <FaqComponent title='Another Questions?' questionName='bikinWebsiteQuestion' handlerSendFaq={handlerSendFaq} handlePageChange={handlePageChange} isBack={true} handlerBack={handlerBack}/>
            :<></>}
            {related.status === true ?
              <>
              <Box
                display='flex'
                justifyContent='start'
                flexDirection='column'
                width='100%'
              >
              {related.data.map((faq) => (
                <Box
                  ms='auto'
                >
                  <Box
                    key={faq.question_id}
                    as='button'
                    height='fit'
                    width='fit'
                    lineHeight='1.2'
                    transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
                    border='1px'
                    px='8px'
                    py='10px'
                    me='10px'
                    borderRadius='8px'
                    fontSize='12px'
                    fontWeight='normal'
                    borderColor='#ff8d0b'
                    bg="white"
                    color='#d97300'
                    textAlign='right'
                    mb='10px'
                    display='flex'
                    justifyContent='space-between'
                    onClick={handlerSendRelated}
                    value={faq.related_question}
                    id={faq.answer}
                    relatedtype={faq.type}
                    type='submit'
                    _hover={{ bg: '#ffead3', color: '#d97300' }}
                    _active={{
                      bg: '#dddfe2',
                      transform: 'scale(0.98)',
                      borderColor: '#bec3c9',
                    }}
                    _focus={{
                      boxShadow:
                        '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
                    }}
                  >
                    {faq.related_question}
                  </Box>
                </Box>
                ))}
              </Box>
              </>
            :<></>}
            </>
          ) : <></>}
          </>}
          {pickTopic && typeConversation.type === 'faq' ? <>
            <Box
                bg="whitePrimary"
                flexGrow={12}
                minHeight="2em"
                borderRadius="8px"
                borderStyle="solid"
                borderWidth="1px"
                boxShadow="sm"
                borderColor="lightGray"
                padding={3}
                color="darkPrimary"
                width="90%"
              >
                <Box display='flex' justifyContent='space-between' mb={isContentVisible ? 2 : 0}>
                  <Text fontSize="sm" fontWeight="semibold" textShadow="none" alignSelf='center'>
                    Pilih Topik
                  </Text>
                  <span
                    onClick={handlerShowHide}
                    style={{
                      padding: '6px',
                      borderRadius: '8px',
                      border: '1px solid #ff8d0b',
                      fontSize: '12px',
                      color: '#ff8d0b',
                      cursor: 'pointer',
                    }}
                  >
                    {isContentVisible ? 'hide' : 'show'}
                  </span>
                </Box>
                {isContentVisible ? <>
                  {topicList.map((faq) => (
                  <Box
                  key={faq.id}
                  as='button'
                  height='fit'
                  width='100%'
                  lineHeight='1.2'
                  transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
                  border='1px'
                  px='8px'
                  py='15px'
                  borderRadius='8px'
                  fontSize='12px'
                  fontWeight='normal'
                  bg='#f5f6f7'
                  borderColor='transparent'
                  color='#4b4f56'
                  textAlign='left'
                  mb='10px'
                  display='flex'
                  justifyContent='space-between'
                  onClick={handlerChangeTopic}
                  value={faq.topic}
                  type='submit'
                  _hover={{ bg: '#ffead3', color: '#ff8d0b' }}
                  _active={{
                    bg: '#dddfe2',
                    transform: 'scale(0.98)',
                    borderColor: '#bec3c9',
                  }}
                  _focus={{
                    boxShadow:
                      '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
                  }}
                >
                  {faq.topic}
                  <ArrowForwardIcon/>
                </Box>
                ))}
                </> : <></>}
              </Box>
          </>: <></>}
          {/* <FormUpload /> */}
        </VStack>
      </VStack>
    </>
  );
}

export default Chat;
