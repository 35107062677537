import { SimpleGrid } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { ToastContainer } from 'react-toastify';
import useSound from 'use-sound';
import { validateAuth } from './app/Auth/AuthAPI';
import {
  authSelector,
  removeAuthValidate,
  updateApiKey,
  updateDataAuth,
} from './app/Auth/AuthSlice';
import {
  changeStatusAlertRunned,
  changeVolumeAlert,
  layoutSetupSelector,
  updateDeviceVersion,
  updateFeatureActive,
  updateFeatureBefore,
  updateHeightBody,
} from './app/Layouts/LayoutSlice';
import { messageSelector, updateEmojiProps } from './app/Message/MessageSlice';
import swushSFX from './assets/sound/swush.mp3';
import BodyCards from './common/Components/BodyCards/BodyCards';
import BottomButton from './common/Components/BottomButton/BottomButton';
import Footers from './common/Components/FooterCards/Footers';
import HeaderCards from './common/Components/HeaderCards/HeaderCards';
import DummyPage from './features/DummyPage/DummyPage';
import './override.css';

function App(props) {
  /* state */
  const { feature, featureBefore, deviceVersion, volumeAlert, runAlert } =
    useSelector(layoutSetupSelector);
  const { status } = useSelector(authSelector);
  const { isOpenEmoji } = useSelector(messageSelector);
  const [propsGrid, setPropsGrid] = useState({});

  /* config */
  const { apiKey } = props;
  const dispatch = useDispatch();

  const [play] = useSound(swushSFX, {
    volume: volumeAlert,
  });

  /* config display */
  const isDisplay250 = useMediaQuery({ query: '(min-width:250px)' });
  const isDisplay374 = useMediaQuery({ query: '(min-width:374px)' });
  const isDisplay424 = useMediaQuery({ query: '(min-width:424px)' });
  const isDisplay765 = useMediaQuery({ query: '(min-width:765px)' });
  const isDisplay1020 = useMediaQuery({ query: '(min-width:1020px)' });
  const isDisplay1400 = useMediaQuery({ query: '(min-width:1400px)' });
  const isDisplay2000 = useMediaQuery({ query: '(min-width:2000px)' });

  /* handler */
  const handlerButtonUpdateFeature = (feature, oldFeature = false) => {
    const valueOldFeature = !oldFeature ? 'not_opened' : oldFeature;
    dispatch(updateFeatureBefore(valueOldFeature));

    if (feature === 'not_opened') {
      dispatch(updateFeatureActive('home'));
    } else {
      dispatch(updateFeatureActive(feature));
    }
  };

  const handlerPlaySound = () => {
    play();
    dispatch(changeStatusAlertRunned(false));
  };

  /* useEffect */
  useEffect(() => {
    if (Boolean(apiKey)) {
      dispatch(updateApiKey(apiKey));

      validateAuth(apiKey)
        .then((response) => {
          const dataRes = response.data.data;
          dispatch(updateDataAuth(dataRes));
        })
        .catch((err) => {
          dispatch(removeAuthValidate());
          console.warn('integration failed, message error:', err);
        });
    } else {
      dispatch(removeAuthValidate());
    }
  }, [apiKey]);

  useEffect(() => {
    let device = '';

    if (isDisplay2000) {
      device = '4k';
    } else if (isDisplay1400) {
      device = 'large_desktop';
    } else if (isDisplay1020) {
      device = 'small_desktop';
    } else if (isDisplay765) {
      device = 'tablet';
    } else if (isDisplay424) {
      device = 'large_mobile';
    } else if (isDisplay374) {
      device = 'medium_mobile';
    } else {
      device = 'small_mobile';
    }

    dispatch(updateDeviceVersion(device));
  }, [
    isDisplay250,
    isDisplay374,
    isDisplay424,
    isDisplay765,
    isDisplay1020,
    isDisplay1400,
    isDisplay2000,
  ]);

  useEffect(() => {
    let propGrids = {};
    let propBody = {};
    let emojiProps = {};

    if (Boolean(isOpenEmoji)) {
      if (
        ['tablet', 'large_mobile', 'medium_mobile', 'small_mobile'].includes(
          deviceVersion
        )
      ) {
        propBody = {
          maxHeight: '170px',
          minHeight: '170px',
        };

        propGrids = {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        };
      } else if (['small_desktop'].includes(deviceVersion)) {
        propBody = {
          maxHeight: '120px',
          minHeight: '120px',
        };

        propGrids = {
          maxW: '350px',
          minW: '350px',
          borderRadius: '15px',
          bottom: '25px',
          right: '3%',
          m: '1rem',
          boxShadow: 'md',
        };

        emojiProps = {
          perLine: 8,
          emojiSize: 20,
          emojiButtonSize: 38,
        };
        dispatch(updateEmojiProps(emojiProps));
      } else {
        propBody = {
          maxHeight: '170px',
          minHeight: '170px',
        };

        propGrids = {
          maxW: '340px',
          minW: '340px',
          borderRadius: '15px',
          bottom: '25px',
          right: '3%',
          m: '1rem',
          boxShadow: 'md',
        };

        emojiProps = {
          perLine: 8,
          emojiSize: 20,
          emojiButtonSize: 38,
        };
        dispatch(updateEmojiProps(emojiProps));
      }
    } else {
      if (
        ['tablet', 'large_mobile', 'medium_mobile', 'small_mobile'].includes(
          deviceVersion
        )
      ) {
        propGrids = {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        };

        propBody = {
          maxHeight: '400px',
          minHeight: '400px',
        };
      } else if (['small_desktop'].includes(deviceVersion)) {
        propBody = {
          maxHeight: '350px',
          minHeight: '350px',
        };

        propGrids = {
          maxW: '350px',
          minW: '350px',
          borderRadius: '15px',
          bottom: '25px',
          right: '3%',
          m: '1rem',
          boxShadow: 'md',
        };

        emojiProps = {
          perLine: 8,
          emojiSize: 20,
          emojiButtonSize: 38,
        };
        dispatch(updateEmojiProps(emojiProps));
      } else {
        propBody = {
          maxHeight: '400px',
          minHeight: '400px',
        };

        propGrids = {
          maxW: '340px',
          minW: '340px',
          borderRadius: '15px',
          bottom: '25px',
          right: '3%',
          m: '1rem',
          boxShadow: 'md',
        };
      }
    }

    setPropsGrid(propGrids);

    dispatch(updateHeightBody(propBody));
  }, [deviceVersion, isOpenEmoji]);

  useEffect(() => {
    window.addEventListener('click', () => {
      dispatch(changeVolumeAlert(0));
      handlerPlaySound();
    });
  }, [status]);

  useEffect(() => {
    // handlerPlaySound();
  }, [runAlert]);

  /* component */
  if (status.toLowerCase() === 'active') {
    return (
      <>
        <ToastContainer limit={5} />
        {/* {REACT_APP_DEV && <DummyPage />} */}
        {feature !== 'not_opened' && (
          <SimpleGrid
            direction="row"
            pos="fixed"
            zIndex="999"
            bg="bgColor"
            fontFamily={`'Inter', sans-serif`}
            {...propsGrid}
          >
            <HeaderCards />
            <BodyCards />
            <Footers />
          </SimpleGrid>
        )}

        {feature === 'not_opened' && (
          <BottomButton
            featureBefore={featureBefore}
            openFeature={(val) => handlerButtonUpdateFeature(val)}
          />
        )}
      </>
    );
  }
}

export default App;
