import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  Select,
  Textarea,
  VStack,
  Box,
  SkeletonText
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { authSelector, clientLogin } from '../../../app/Auth/AuthSlice';
import {
  updateFeatureActive,
  updateFeatureBefore,
} from '../../../app/Layouts/LayoutSlice';
import {
  getListDepartments,
  getListTopics,
  getSocialMediaList,
  selectSelector,
} from '../../../app/Select/SelectSlice';
import { notify } from '../../Utils/helpers';
import ButtonSosmed from '../Button/ButtonSosmed';
import FormButton from '../Button/FormButton';
import { getQuestions, faqSelector, changeStatusAnswered, updateTopic, updateCurrentPage } from '../../../app/FAQ/FaqSlice';
import { useState } from 'react';
import FaqComponent from '../FaqCard/FaqComponent';

function Form() {
  /* configuration */
  const dispatch = useDispatch();
  const { questions }  = useSelector(faqSelector);
  const [topicName, setTopicName] = useState('')

  const initialValues = {
    name: '',
    email: '',
    telp: '',
    topic: '',
    department: '',
    message: '',
  };

  const schemas = Yup.object({
    name: Yup.string().required('Nama wajib di isi!'),
    email: Yup.string()
      .email('Harus format email!')
      .required('Email wajib di isi!'),
    telp: Yup.number()
      .required('No Telepon wajib di isi!'),
    topic: Yup.object().required('Topik wajib di pilih!'),
    department: Yup.object().required('Departemen wajib di pilih!'),
    message: Yup.string()
      .min(5, 'Panjang pesan minimal 5!')
      .required('Pesan wajib di isi!'),
  });

  const handlerButtonUpdateFeature = (feature, oldFeature = false) => {
    dispatch(updateFeatureBefore('form_message'));
    dispatch(updateFeatureActive('home'));
  };

  /* state and selector */
  const { apiKey, uuid, userCompanyName } = useSelector(authSelector);
  const { topicList, departmentList, socialMediaList } =
    useSelector(selectSelector);

  /* useEffect */
  useEffect(() => {
    dispatch(getSocialMediaList(apiKey));
    dispatch(getListTopics(apiKey));
    dispatch(getListDepartments(apiKey));
  }, [apiKey]);

  /* component */
  const CompOptions = (params) => {
    const { data } = params;
    if (Boolean(data)) {
      return data.map((val, index) => {
        const objOpt = {
          optId: val.id,
          optName: val.name,
        };
        return (
          <option key={index} value={JSON.stringify(objOpt)}>
            {val.name}
          </option>
        );
      });
    }
  };

  /* handler */
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schemas,
    onSubmit: (values, actions) => {
      let chooseTopic = JSON.parse(values.topic);

      let chooseDepartment = JSON.parse(values.department);
      const message = values.message.trim();

      if (message.length < 1) {
        return notify(
          'error',
          5000,
          'Pesan harus berupa text ataupun angka, minimal 5 karakter',
          'top_right'
        );
      }

      const currentUrl = window.location.href;

      const dataSender = {
        name: values.name,
        email: values.email,
        telp: values.telp,
        no_whatsapp: values.telp,
        company_uuid: uuid,
        company_name: userCompanyName,
        department_id: chooseDepartment.optId.toString(),
        department_name: chooseDepartment.optName,
        topic_id: chooseTopic.optId.toString(),
        topic_name: chooseTopic.optName,
        message,
        api_key: apiKey,
        site_url: currentUrl,
        type: 'general',
        question_id: null
      };

      dispatch(clientLogin(dataSender));
      actions.resetForm();
    },
  });

  const handlePageChange = (newPage) => {
    const currentTopic = topicName.toLowerCase();

    if(currentTopic === 'billing'){
      if (newPage >= 1 && newPage <= questions.billingQuestion.totalPages) {
        dispatch(updateCurrentPage({ category: currentTopic, currentPage: newPage }));
      }
    }else if(currentTopic === 'domain'){
      if (newPage >= 1 && newPage <= questions.domainQuestion.totalPages) {
        dispatch(updateCurrentPage({ category: currentTopic, currentPage: newPage }));
      }
    }else if(currentTopic === 'hosting'){
      if (newPage >= 1 && newPage <= questions.hostingQuestion.totalPages) {
        dispatch(updateCurrentPage({ category: currentTopic, currentPage: newPage }));
      }
    }else if(currentTopic === 'teknis'){
      if (newPage >= 1 && newPage <= questions.teknisQuestion.totalPages) {
        dispatch(updateCurrentPage({ category: currentTopic, currentPage: newPage }));
      }
    }else if(currentTopic === 'other'){
      if (newPage >= 1 && newPage <= questions.otherQuestion.totalPages) {
        dispatch(updateCurrentPage({ category: currentTopic, currentPage: newPage }));
      }
    }else if(currentTopic === 'bikin website'){
      if (newPage >= 1 && newPage <= questions.bikinWebsiteQuestion.totalPages) {
        dispatch(updateCurrentPage({ category: currentTopic, currentPage: newPage }));
      }
    }
  };

  const handlerSendFaq = (e) => {
    let chooseTopic = JSON.parse(formik.values.topic);
    let chooseDepartment = JSON.parse(formik.values.department);
    let message = e.target.value;
    dispatch(updateTopic(chooseTopic.optName));
    const currentUrl = window.location.href;
    if(!formik.values.name || !formik.values.email || !formik.values.telp || !formik.values.telp || !formik.values.topic || !formik.values.department){
      return;
    }

    const dataSender = {
      name: formik.values.name,
      email: formik.values.email,
      telp: formik.values.telp,
      no_whatsapp: formik.values.telp,
      company_uuid: uuid,
      company_name: userCompanyName,
      department_id: chooseDepartment.optId.toString(),
      department_name: chooseDepartment.optName,
      topic_id: chooseTopic.optId.toString(),
      topic_name: chooseTopic.optName,
      message,
      api_key: apiKey,
      site_url: currentUrl,
      type: 'faq',
      question_id: e.target.id
    };

    dispatch(changeStatusAnswered(false));
    dispatch(clientLogin(dataSender));
  }

  useEffect(() => {
    if (formik.values.topic) {
      let chooseTopic = JSON.parse(formik.values.topic);
      setTopicName(chooseTopic.optName);
      dispatch(updateTopic(chooseTopic.optName));
      if (chooseTopic.optName === 'Billing') {
        dispatch(getQuestions('billing'));
      }else if(chooseTopic.optName === 'Domain'){
        dispatch(getQuestions('domain'));
      }else if(chooseTopic.optName === 'Hosting'){
        dispatch(getQuestions('hosting'));
      }else if(chooseTopic.optName === 'Other'){
        dispatch(getQuestions('other'));
      }else if(chooseTopic.optName === 'Teknis'){
        dispatch(getQuestions('teknis'));
      }else if(chooseTopic.optName === 'Bikin Website'){
        dispatch(getQuestions('bikin website'));
      }
    }
  }, [formik.values.topic]);

  return (
    <>
      <Flex mx="3" mt="3" mb="0">
        <Button
          size="sm"
          py="0"
          my="0"
          backgroundColor="transparent"
          color="darkGray"
          cursor="pointer"
          border="none"
          boxShadow="none"
          _hover={{
            backgroundColor: 'whitePrimaryHover',
            color: 'darkSecondary',
          }}
          title="Kembali"
          onClick={() => handlerButtonUpdateFeature()}
        >
          <FaArrowLeft />
        </Button>
      </Flex>
      <Flex px="5" pt="0" pb="2" justify="center">
        <Heading my={0} as="h6" size="sm" textAlign="center">
          Live Chat
        </Heading>
      </Flex>
      <VStack as="form" mx="3" mt="2" onSubmit={formik.handleSubmit}>
        <FormControl
          isInvalid={formik.errors.name && formik.touched.name}
        >
          <Input
            id="name"
            name="name"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.name}
            placeholder="   Nama"
            borderRadius="9px"
            bg="whitePrimary"
            borderColor="lightGray"
            color="darkPrimary"
            focusBorderColor="lightGray"
            size="sm"
            _placeholder={{ color: 'normalGray' }}
            paddingInlineEnd={0}
            paddingInlineStart={0}
            paddingLeft={0.5}
          />
          <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
        </FormControl>
        <FormControl
          isInvalid={formik.errors.email && formik.touched.email}
        >
          <Input
            id="email"
            name="email"
            type="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            placeholder="   Email"
            borderRadius="9px"
            bg="whitePrimary"
            borderColor="lightGray"
            focusBorderColor="lightGray"
            color="darkPrimary"
            size="sm"
            _placeholder={{ color: 'normalGray' }}
            paddingInlineEnd={0}
            paddingInlineStart={0}
            paddingLeft={0.5}
          />
          <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
        </FormControl>
        <FormControl
          isInvalid={formik.errors.telp && formik.touched.telp}
        >
          <Input
            id="telp"
            name="telp"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.telp}
            placeholder="   Nomor Telepon"
            borderRadius="9px"
            bg="whitePrimary"
            borderColor="lightGray"
            focusBorderColor="lightGray"
            color="darkPrimary"
            size="sm"
            _placeholder={{ color: 'normalGray' }}
            paddingInlineEnd={0}
            paddingInlineStart={0}
            paddingLeft={0.5}
          />
          <FormErrorMessage>{formik.errors.telp}</FormErrorMessage>
        </FormControl>
        <FormControl
          isInvalid={formik.errors.department && formik.touched.department}
        >
          <Select
            id="department"
            name="department"
            onChange={formik.handleChange}
            value={formik.values.department}
            placeholder="Departemen"
            borderRadius="9px"
            bg="whitePrimary"
            borderColor="lightGray"
            focusBorderColor="lightGray"
            color="darkPrimary"
            _placeholder={{ color: 'normalGray' }}
            _hover={{ cursor: 'pointer' }}
            size="sm"
          >
            <CompOptions key={1} data={departmentList} />
          </Select>
          <FormErrorMessage>{formik.errors.department}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={formik.errors.topic && formik.touched.topic}>
          <Select
            id="topic"
            name="topic"
            onChange={formik.handleChange}
            value={formik.values.topic}
            placeholder="Topik"
            borderRadius="9px"
            bg="whitePrimary"
            borderColor="lightGray"
            focusBorderColor="lightGray"
            color="darkPrimary"
            _placeholder={{ color: 'normalGray' }}
            _hover={{ cursor: 'pointer' }}
            size="sm"
          >
            <CompOptions key={0} data={topicList} />
          </Select>
          <FormErrorMessage>{formik.errors.topic}</FormErrorMessage>
        </FormControl>
        {questions.status === 'pending' ? <>
          <Box padding='6' boxShadow='lg' bg='white' w='100%'>
            <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
          </Box>
        </> : <>
          {topicName === 'Billing' && questions.billingQuestion.data !== null ? (
            <FaqComponent questionName='billingQuestion' title='FAQ (Frequently Asked Questions)' handlerSendFaq={handlerSendFaq} handlePageChange={handlePageChange} isBack={false}/>
          ) : topicName === 'Domain' && questions.domainQuestion.data !== null ? (
            <FaqComponent questionName='domainQuestion' title='FAQ (Frequently Asked Questions)' handlerSendFaq={handlerSendFaq} handlePageChange={handlePageChange} isBack={false}/>
          ) : topicName === 'Other' && questions.otherQuestion.data !== null ? (
            <FaqComponent questionName='otherQuestion' title='FAQ (Frequently Asked Questions)' handlerSendFaq={handlerSendFaq} handlePageChange={handlePageChange} isBack={false}/>
          ) : topicName === 'Hosting' && questions.hostingQuestion.data !== null ? (
            <FaqComponent questionName='hostingQuestion' title='FAQ (Frequently Asked Questions)' handlerSendFaq={handlerSendFaq} handlePageChange={handlePageChange} isBack={false}/>
          ) : topicName === 'Teknis' && questions.teknisQuestion.data !== null ? (
            <FaqComponent questionName='teknisQuestion' title='FAQ (Frequently Asked Questions)' handlerSendFaq={handlerSendFaq} handlePageChange={handlePageChange} isBack={false}/>
          ) : topicName === 'Bikin Website' && questions.bikinWebsiteQuestion.data !== null ? (
            <FaqComponent questionName='bikinWebsiteQuestion' title='FAQ (Frequently Asked Questions)' handlerSendFaq={handlerSendFaq} handlePageChange={handlePageChange} isBack={false}/>
          ) : <></>}
        </>}
        
        <FormControl
          isInvalid={formik.errors.message && formik.touched.message}
        >
          <Textarea
            id="message"
            name="message"
            onChange={formik.handleChange}
            value={formik.values.message}
            placeholder=" Tulis Pertanyaan disini"
            borderRadius="9px"
            bg="whitePrimary"
            borderColor="lightGray"
            focusBorderColor="lightGray"
            color="darkPrimary"
            _placeholder={{ color: 'normalGray' }}
            size="sm"
            maxHeight="50px"
            resize="none"
            paddingInlineEnd={0}
            paddingInlineStart={0}
            paddingLeft={0.5}
            css={{
              '&::-webkit-scrollbar': {
                width: '6px',
              },
              '&::-webkit-scrollbar-track': {
                width: '1px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#D9D9D9',
                borderRadius: '24px',
              },
            }}
          />
          <FormErrorMessage>{formik.errors.message}</FormErrorMessage>
        </FormControl>
        <FormButton />
      </VStack>
      <ButtonSosmed data={socialMediaList} />
    </>
  );
}

export default Form;
