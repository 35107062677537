import { extendTheme } from '@chakra-ui/react';
import '@fontsource/inter';

const theme = {
  config: {
    useSystemColorMode: false,
    initialColorMode: 'light',
  },
  colors: {
    primary: '#dd831d',
    primaryHover: '#BD7019',
    primaryLight: '#EFBC83',
    // primary: '#f9ac50',
    // primaryHover: '#EB8C00',
    // primaryLight: '#FFDFAF',
    whitePrimary: '#FEFEFE',
    whitePrimaryHover: '#EDEDED',
    bgColor: '#F6F6F6',
    darkPrimary: '#141414',
    darkSecondary: '#222222',
    darkThird: '#323645',
    normalGray: '#B7B7B7',
    lightGray: '#D9D9D9',
    darkGray: '#767676',
  },
  components: {
    Button: {
      variants: {
        'btn-bottom-cevo': {
          bg: 'whitePrimary',
          m: '1rem',
          p: '10px',
          right: '1%',
          borderRadius: '50%',
          pos: 'fixed',
          bottom: '10px',
          border: '2.5px solid',
          borderColor: 'primary',
          textAlign: 'center',
        },
        'btn-submit': {
          bg: 'primary',
          p: '10px',
          borderRadius: '7px',
          border: '2.5px solid',
          borderColor: 'primary',
          width: '100%',
          textAlign: 'center',
          fontWeight: 'normal',
          fontSize: '14px',
          color: 'whitePrimary',
          _hover: {
            bg: 'primaryHover',
            _disabled: {
              bg: 'primaryHover',
              opacity: '0.5',
            },
          },
        },
        'btn-social-media': {
          bg: 'whitePrimary',
          borderRadius: '9px',
          border: '2.5px solid',
          borderColor: 'normalGray',
          width: '100%',
          textAlign: 'center',
          fontWeight: 'normal',
          fontSize: '14px',
          color: 'darkPrimary',
          _hover: {
            bg: 'whitePrimaryHover',
          },
        },
      },
    },
    Devider: {
      'line-cevo': {
        orientation: 'horizontal',
        color: 'lightGray',
        borderStyle: 'solid',
      },
    }
  },
  styles: {},
};

export default extendTheme(theme);
