import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faqSelector, searchQuestion } from '../../../../app/FAQ/FaqSlice';
import { Box, InputGroup, InputLeftElement, Input } from '@chakra-ui/react';
import { ArrowForwardIcon, SearchIcon } from '@chakra-ui/icons';

const SearchFaq = ({ handlerSendFaq, handlerHide, questionName, handlerShow }) => {
  const dispatch = useDispatch();
  const { questions, topic } = useSelector(faqSelector);

  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = (event) => {
    const newSearchQuery = event.target.value;
    setSearchQuery(newSearchQuery);

    dispatch(searchQuestion(newSearchQuery, topic));
    setSearchResults(questions[questionName].searchResults);

    // Hide the content here
    handlerHide();
  };

  useEffect(() => {
    if(searchQuery === ''){
      handlerShow();
    }
  }, [searchQuery])
  

  return (
    <Box>
      <InputGroup mb='15px'>
        <InputLeftElement pointerEvents='none'>
          <SearchIcon color='gray.300' />
        </InputLeftElement>
        <Input
          focusBorderColor='#ff8d0b'
          type='text'
          placeholder='Search FAQ'
          fontSize='14px'
          onChange={handleSearch}
        />
      </InputGroup>
      {searchQuery === '' ? <></> : <>
      {searchResults.map((faq) => (
        <Box
          key={faq.id}
          as='button'
          height='fit'
          width='100%'
          lineHeight='1.2'
          transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
          border='1px'
          px='8px'
          py='15px'
          borderRadius='8px'
          fontSize='12px'
          fontWeight='normal'
          bg='#f5f6f7'
          borderColor='transparent'
          color='#4b4f56'
          textAlign='left'
          mb='10px'
          display='flex'
          justifyContent='space-between'
          onClick={(e) => {
            handlerSendFaq(e);
          }}
          value={faq.question}
          id={faq.id}
          type='submit'
          _hover={{ bg: '#ffead3', color: '#ff8d0b' }}
          _active={{
            bg: '#dddfe2',
            transform: 'scale(0.98)',
            borderColor: '#bec3c9',
          }}
          _focus={{
            boxShadow:
              '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
          }}
        >
          {faq.question}
          <ArrowForwardIcon />
        </Box>
      ))}
      </>}
      
    </Box>
  );
};

export default SearchFaq;
