import { ChakraProvider } from '@chakra-ui/react';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import persistStore from 'redux-persist/es/persistStore'
import {PersistGate} from 'redux-persist/integration/react'

/* theme ui */
import theme from './theme';

const windowUrl = window.location.search;
const { REACT_APP_DEV } = process.env;

const params = new URLSearchParams(windowUrl);
let apiKey = '';
if (REACT_APP_DEV) {
  apiKey = '$2y$10$V87UHF9HsWHcGRC9JMmpuuZJ6lnUObMKb/gMS9iOFVjoBuK3UwZRW';
} else {
  apiKey = params.get('api_key');

  if (!Boolean(apiKey)) {
    const el = document.getElementById('qchat-client');
    const src = el.getAttribute('src');
    const url = new URL(src);
    apiKey = url.searchParams.get('api_key');

    const div = document.createElement('div');
    div.id = 'root-client-chatvolution';
    document.body.appendChild(div);
  }
}

let persistor = persistStore(store)
const container = document.getElementById('root-client-chatvolution');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme} cssVarsRoot="#root-client-chatvolution">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App apiKey={apiKey} />
        </PersistGate>
      </Provider>
    </ChakraProvider>
  </React.StrictMode>
);
reportWebVitals();
