import Socket from '../Socket';

export const refreshAuth = (data) => {
  Socket.disconnect();
  Socket.connect();
  setTimeout(() => {
    Socket.emit('chat.new', data);
  }, 3000);
};

export const sendMessage = (chatId, message, file = {}, type, conversation) => {
  const dataSenderDefault = { chatId, message };
  const additionalData = {type, conversation}
  const resultDataSender = Object.assign(dataSenderDefault, file, additionalData);
  Socket.emit('message', resultDataSender);
};

export const resolveChat = (chatId) => {
  Socket.emit('chat.end', {
    chatId,
  });
};

export const typingMessage = (chatId,isStatus)=>{
  Socket.emit("typing",{
    chatId: chatId,
    is_typing: isStatus,
  })
}