import React, { useState } from 'react';
import { Box, Text, Circle } from '@chakra-ui/react';
import { ArrowForwardIcon, ArrowBackIcon } from '@chakra-ui/icons';
import { useSelector } from 'react-redux';
import { faqSelector } from '../../../app/FAQ/FaqSlice';
import SearchFaq from './SearchFaq/SearchFaq';

const FaqComponent = ({ title, handlerSendFaq, handlePageChange, questionName, isBack, handlerBack }) => {
  const { questions } = useSelector(faqSelector);
  const [isContentVisible, setIsContentVisible] = useState(!isBack);
  const [isHide, setIsHide] = useState(false);

  const handlerHide = () => {
    setIsHide(true)
  }

  const handlerShow = () => {
    setIsHide(false)
  }
  
  return (
    <Box
      m="3"
      bg="whitePrimary"
      flexGrow={12}
      minHeight="2em"
      borderRadius="8px"
      borderStyle="solid"
      borderWidth="1px"
      boxShadow="sm"
      borderColor="lightGray"
      padding={3}
      color="darkPrimary"
      width={isBack ? "90%" : "100%"}
    >
      <Box display='flex' justifyContent='space-between' mb={isContentVisible ? 2 : 0}>
        <Text fontSize="sm" fontWeight="semibold" textShadow="none" alignSelf='center'>
          {title}
        </Text>
        <span
          onClick={() => setIsContentVisible(!isContentVisible)}
          style={{
            padding: '6px',
            borderRadius: '8px',
            border: '1px solid #ff8d0b',
            fontSize: '12px',
            color: '#ff8d0b',
            cursor: 'pointer',
          }}
        >
          {isContentVisible ? 'hide' : 'show'}
        </span>
      </Box>
      {isContentVisible && (
        <>
          {questions[questionName].totalPages > 1 &&(
            <SearchFaq handlerSendFaq={handlerSendFaq} handlerHide={handlerHide} questionName={questionName} handlerShow={handlerShow}/>
          )}
          {!isHide ? <>
            {questions[questionName].data
              .slice(
                (questions[questionName].currentPage - 1) * questions[questionName].itemsPerPage,
                questions[questionName].currentPage * questions[questionName].itemsPerPage
              )
              .map(faq => (
                <Box
                  key={faq.id}
                  as='button'
                  height='fit'
                  width='100%'
                  lineHeight='1.2'
                  transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
                  border='1px'
                  px='8px'
                  py='15px'
                  borderRadius='8px'
                  fontSize='12px'
                  fontWeight='normal'
                  bg='#f5f6f7'
                  borderColor='transparent'
                  color='#4b4f56'
                  textAlign='left'
                  mb='10px'
                  display='flex'
                  justifyContent='space-between'
                  onClick={(e) => {
                    handlerSendFaq(e);
                    setIsContentVisible(false);
                  }}
                  value={faq.question}
                  id={faq.id}
                  type='submit'
                  _hover={{ bg: '#ffead3', color: '#ff8d0b' }}
                  _active={{
                    bg: '#dddfe2',
                    transform: 'scale(0.98)',
                    borderColor: '#bec3c9',
                  }}
                  _focus={{
                    boxShadow:
                      '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
                  }}
                >
                  {faq.question}
                  <ArrowForwardIcon/>
                </Box>
              ))}
            {isBack ? 
              <Box
                as='button'
                height='fit'
                width='100%'
                lineHeight='1.2'
                transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
                border='1px'
                px='8px'
                py='15px'
                borderRadius='8px'
                fontSize='12px'
                fontWeight='normal'
                bg='#f5f6f7'
                borderColor='transparent'
                color='#4b4f56'
                textAlign='left'
                mb='10px'
                display='flex'
                justifyContent='space-between'
                onClick={handlerBack}
                type='submit'
                _hover={{ bg: '#ffead3', color: '#ff8d0b' }}
                _active={{
                  bg: '#dddfe2',
                  transform: 'scale(0.98)',
                  borderColor: '#bec3c9',
                }}
                _focus={{
                  boxShadow:
                    '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
                }}
              >
                Pilih topik lain
                <ArrowForwardIcon/>
              </Box> : <></>}
            {questions[questionName].totalPages > 1 && (
              <Box display='flex' justifyContent='space-between'>
                <Box
                  as='button'
                  height='fit'
                  width='fit'
                  lineHeight='1.2'
                  transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
                  border='1px'
                  px='8px'
                  py='8px'
                  borderRadius='8px'
                  fontSize='16px'
                  fontWeight='normal'
                  bg='#f5f6f7'
                  borderColor='transparent'
                  color='#4b4f56'
                  textAlign='left'
                  mb='10px'
                  display='flex'
                  justifyContent='space-between'
                  onClick={() => handlePageChange(questions[questionName].currentPage - 1)}
                  type='submit'
                  _hover={{ bg: '#ffead3', color: '#ff8d0b' }}
                  _active={{
                    bg: '#dddfe2',
                    transform: 'scale(0.98)',
                    borderColor: '#bec3c9',
                  }}
                  _focus={{
                    boxShadow:
                      '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
                  }}
                >
                  <ArrowBackIcon/>
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center">
                  {Array.from({ length: questions[questionName].totalPages }, (_, index) => (
                    <Circle
                      key={index}
                      size="10px"
                      bg={questions[questionName].currentPage === index + 1 ? '#ff8d0b' : 'gray.200'}
                      color={questions[questionName].currentPage === index + 1 ? 'white' : 'black'}
                      fontSize="md"
                      cursor="pointer"
                      marginRight="5px"
                      onClick={() => handlePageChange(index + 1)}
                    />
                  ))}
                </Box>
                <Box
                  as='button'
                  height='fit'
                  width='fit'
                  lineHeight='1.2'
                  transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
                  border='1px'
                  px='8px'
                  py='8px'
                  borderRadius='8px'
                  fontSize='16px'
                  fontWeight='normal'
                  bg='#f5f6f7'
                  borderColor='transparent'
                  color='#4b4f56'
                  textAlign='left'
                  mb='10px'
                  display='flex'
                  justifyContent='space-between'
                  onClick={() => handlePageChange(questions[questionName].currentPage + 1)}
                  type='submit'
                  _hover={{ bg: '#ffead3', color: '#ff8d0b' }}
                  _active={{
                    bg: '#dddfe2',
                    transform: 'scale(0.98)',
                    borderColor: '#bec3c9',
                  }}
                  _focus={{
                    boxShadow:
                      '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
                  }}
                >
                  <ArrowForwardIcon/>
                </Box>
              </Box>
            )}
          </> : <></>}
          
        </>
      )}
    </Box>
  );
};

export default FaqComponent;
