import React, {useState, useEffect} from 'react';
import {
  Avatar,
  AvatarBadge,
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  Tooltip,
  Modal,
  Button,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  useDisclosure
} from '@chakra-ui/react';
import { FaCompressAlt, FaEllipsisH, FaPowerOff } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../../app/Auth/AuthSlice';
import { layoutSetupSelector } from '../../../app/Layouts/LayoutSlice';
import { messageSelector } from '../../../app/Message/MessageSlice';
import avatarDefault from '../../../assets/images/avatar/avatar-4.png';
import Socket from '../../WebSocket/Socket.js';
import { resolveChat } from '../../WebSocket/Clients/ClientActions';
import { clearFaq } from '../../../app/FAQ/FaqSlice'

const CompAvatar = React.memo(
  function CompAvatar({ avatar }) {
    const fixAvatar = avatar === undefined ? avatarDefault : avatar;

    return (
      <>
        <Box ml="-3" mr="3">
          <Avatar
            name="avatar-img"
            src={fixAvatar}
            loading="lazy"
            showBorder="true"
          >
            <AvatarBadge
              borderColor="green.500"
              bg="green.500"
              boxSize="0.5em"
              borderWidth="0.25em"
            />
          </Avatar>
        </Box>
      </>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.avatar === nextProps.avatar;
  }
);

const AvatarRender = (params) => {
  const { data } = params;
  const { typeConversation } = useSelector(authSelector);

  if (Boolean(data.agentId) && !Boolean(data.isTransfered)) {
    return <CompAvatar avatar={data.agentAvatar} />;
  }

  if (!Boolean(data.agentId) && !data.isTransfered && typeConversation.type === 'faq') {
    return <CompAvatar avatar={data.agentAvatar} />;
  }
};

function HeaderDetaiLChat(props) {
  const dispatch = useDispatch();
  const { feature } = useSelector(layoutSetupSelector);
  const { agentSessions, typeConversation } = useSelector(authSelector);
  const { chatId } = useSelector(messageSelector);

  const { closeFeature } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  /* handler */
  const handlerResolveChat = () => {
    dispatch(clearFaq());
    resolveChat(chatId);
    onClose(true);
  };
  const [isTyping, setIsTyping] = useState(false);

  //socket untuk typing
  useEffect(() => {
    Socket.on("typing",(response)=>{
      setIsTyping(true);
      setTimeout(() => {
        setIsTyping(false);
      }, 3000);
    });
  }, [])

  /* component */
  const HeaderTitle = (params) => {
    const { data } = params;
    if (Boolean(data.agentId) && !data.isTransfered) {
      return (
        <>
          <Tooltip
            placement="top-start"
            label={data.agentName}
            aria-label={data.agentName}
            zIndex="inherit"
            hasArrow
            bg="darkGray"
          >
            <Text
              fontSize="md"
              fontWeight="bold"
              color="whitePrimary"
              noOfLines={1}
              m={0}
              _hover={{ cursor: 'pointer' }}
            >
              {data.agentName}
            </Text>
          </Tooltip>

          <Text fontSize="sm" fontWeight="400" m={0} color="whitePrimaryHover">
            {isTyping ? 'Typing...' : 'Online'}
          </Text>
        </>
      );
    }else if (!Boolean(data.agentId) && !data.isTransfered && typeConversation.type === 'faq') {
      return (
        <>
          <Tooltip
            placement="top-start"
            label="Virtual Assistance"
            aria-label="Virtual Assistance"
            zIndex="inherit"
            hasArrow
            bg="darkGray"
          >
            <Text
              fontSize="md"
              fontWeight="bold"
              color="whitePrimary"
              noOfLines={1}
              m={0}
              _hover={{ cursor: 'pointer' }}
            >
              Virtual Assistance
            </Text>
          </Tooltip>

          <Text fontSize="sm" fontWeight="400" m={0} color="whitePrimaryHover">
            {isTyping ? 'Typing...' : 'Online'}
          </Text>
        </>
      );
      } else if (!Boolean(data.agentId) && !data.isTransfered && typeConversation.faq === 'general') {
      return (
        <>
          <Text
            fontSize="md"
            fontWeight="bold"
            color="whitePrimary"
            noOfLines={1}
            my={2}
            _hover={{ cursor: 'text' }}
          >
            Menunggu pesan diambil...
          </Text>
        </>
      );
    } else {
      return (
        <>
          <Text
            fontSize="md"
            fontWeight="bold"
            color="whitePrimary"
            noOfLines={1}
            my={2}
            _hover={{ cursor: 'text' }}
          >
            Di arahkan ke agent lain...
          </Text>
        </>
      );
    }
  };

  return (
    <>
      <Flex>
        <AvatarRender data={agentSessions} />
        <Box mr="2" mt="1">
          <HeaderTitle data={agentSessions} />
        </Box>
        <Spacer />
        <Box mr="-5" mt="-3">
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              color="whitePrimary"
              backgroundColor="transparent"
              border="none"
              cursor="pointer"
              icon={<FaEllipsisH />}
              _active={{ backgroundColor: 'bgColor', color: 'darkPrimary' }}
              _hover={{
                backgroundColor: 'bgColor',
                color: 'darkPrimary',
              }}
              variant="ghost"
            />
            <MenuList>
              <MenuItem
                className="btn-perkecil-cevo"
                backgroundColor="transparent"
                border="none"
                cursor="pointer"
                onClick={() => closeFeature(feature)}
                icon={<FaCompressAlt />}
              >
                Memperkecil chat
              </MenuItem>
              {feature !== 'rate_form' && (
                <MenuItem
                  backgroundColor="transparent"
                  border="none"
                  cursor="pointer"
                  color="red.500"
                  _hover={{ color: 'whitePrimary', backgroundColor: 'red.500' }}
                  icon={<FaPowerOff />}
                  onClick={onOpen}
                >
                  Selesaikan chat
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        </Box>
      </Flex>
      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose} size="xs">
        <ModalOverlay />
        <ModalContent  >
          <ModalHeader>Selesaikan Chat?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text  mb='1rem'>
              Apakah anda ingin menyelesaikan chat dengan kami?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button variant='ghost'  colorScheme='blue' mr={3} onClick={onClose}>
              Tutup
            </Button>
            <Button colorScheme='red' onClick={() => handlerResolveChat()}>Selesaikan</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default HeaderDetaiLChat;
