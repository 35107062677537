import { configureStore, combineReducers } from '@reduxjs/toolkit';
import LayoutReducer from '../app/Layouts/LayoutSlice';
import AuthReducer from '../app/Auth/AuthSlice';
import SelectReducer from '../app/Select/SelectSlice';
import MessageReducer from '../app/Message/MessageSlice';
import FaqReducer from '../app/FAQ/FaqSlice';
import {
	FLUSH, PAUSE,
	PERSIST, persistReducer, PURGE,
	REGISTER, REHYDRATE
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const { REACT_APP_DEV } = process.env;
const persistConfig = {
	key: 'root',
	version: 1,
	storage,
	blacklist: [
		'selectSetup',
		'layoutSetup',
    'messageSetup'
	],
}

const reducer = combineReducers({
	selectSetup: SelectReducer,
  layoutSetup: LayoutReducer,
  authSetup: AuthReducer,
  messageSetup: MessageReducer,
  faqSetup: FaqReducer,
})

const persistedReducer = persistReducer(persistConfig, reducer)
export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    // https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER
        ],
      },
    }),
  reducer: persistedReducer,
  devTools: REACT_APP_DEV === 'false' ? false : true,
});
